import { Form, Spin, message } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';
import { createConfigurations } from '../../apis/configurations';
import { ConfigurationI } from '../../interfaces/configuration';
import ConfigurationForm from '../../components/forms/configuration/Form';
import NonClosableModal from '../../components/NonClosableModal';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import Text from '../../components/Text';
import SuccessSubmittedItem from '../../components/shared/SuccessSubmittedItem';

const ConfigurationNewPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type') ?? '';
  const [isConfirmModal, setConfirmModal] = useState<boolean>(false);
  const [newCode, setNewCode] = useState<string>();

  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate: createMutate, isLoading: isPageLoading } = useMutation(createConfigurations, {
    onSuccess: async (data) => {
      setNewCode(data.code);
      queryClient.invalidateQueries();
      setConfirmModal(true);
    },
    onError: () => {
      message.error('Your action failed! Please try again');
    },
  });

  const handleSubmit = async (values: Partial<ConfigurationI>) => {
    createMutate(values);
  };

  const handleModalCancel = () => {
    setConfirmModal(false);
  };

  return (
    <Spin spinning={isPageLoading}>
      <div className="tw-p-4">
        <CreateUpdateTopSection label="Create Configuration" labelButton="Create" form={form} />
        <ConfigurationForm form={form} type={type} handleSubmit={handleSubmit} />
        <NonClosableModal isVisible={isConfirmModal} handleCancel={handleModalCancel} width={537}>
          <div className="tw-text-center tw-py-4 tw-px-2">
            <CheckCircleIcon width={48} height={48} color="#0A7A08" />
            <Text variant="h3" className="tw-mt-2 tw-mb-3">
              You have created a new hardware configuration
            </Text>
            <div className="notice tw-mb-8 tw-text-grey-700">
              <Text variant="bodyMd" className="!tw-block">
                Few actions are available for this entry.
              </Text>
              <Text variant="bodyMd" className="!tw-block">
                You can choose the options below
              </Text>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-3">
              <SuccessSubmittedItem
                url={`/configurations/${newCode}`}
                title="View the Hardware Configuration"
                description={['View the profile of Hardware Configuration you have just created']}
              />
              <SuccessSubmittedItem
                url={`/configurations/apply?code=${newCode}`}
                title="Apply this configuration to Hardware"
                description={['Scan hardware to apply this configuration']}
              />
            </div>
          </div>
        </NonClosableModal>
      </div>
    </Spin>
  );
};

export default ConfigurationNewPage;
