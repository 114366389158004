import { Button, Dropdown, MenuProps, Space, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { PAGE_SIZE } from '../../constants';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import PlusIcon from '../../svgs/PlusIcon';
import { handleFilterParams } from '../../utils/global';
import { HardwareSearchParamsI } from '../../interfaces/hardware';
import { getHardwareAllocationsList } from '../../apis/hardwareAllocations';
import HardwareAllocationsTable from '../../components/tables/HardwareAllocations';
import SendIcon from '../../svgs/SendIcon';
import ReceiveIcon from '../../svgs/ReceiveIcon';
import HardwareAllocationsCardList from '../../components/card-list/HardwareAllocations';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import Text from '../../components/Text';
import AllocationFilterForm from '../../components/forms/hardware/AllocationFilter';
import DisplayLastUpdateApp from '../../components/DisplayLastUpdate';
import SizeBox from '../../components/SizeBox';
import EmptyData from '../../components/EmptyData';

const HardwareAllocationsPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'hardware-allocations');
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const search = urlSearch.get('search');
    const type = urlSearch.get('filter[type]')?.split(',') ?? [];
    const siteIdsFilter = urlSearch.get('filter[siteIds]')?.split(',') ?? [];
    const status = urlSearch.get('filter[status]')?.split(',') ?? [];
    const condition = urlSearch.get('filter[condition]')?.split(',') ?? [];
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      search,
      filter: { status, type, condition, siteIds: siteIdsFilter },
    } as HardwareSearchParamsI & { viewMode?: string };
  }, [urlSearch]);

  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);
  const {
    data: { allocationHistories = [], total = 0 } = {},
    isLoading,
    refetch: refetchData,
  } = useQuery(['hardware-allocations', 'list', queryParams], () => getHardwareAllocationsList(queryParams), {
    select({ data: { allocationHistories, total } }) {
      return { allocationHistories, total };
    },
  });

  const currentPage = queryParams?.page ?? 1;
  const pagination: any = {
    total,
    current: currentPage,
    pageSize: queryParams.limit ?? PAGE_SIZE,
    showTotal: (total: string) => `${total} Items`,
  };

  const setQueryParams = (params: Partial<HardwareSearchParamsI>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  const handleFilterChange = (values: HardwareSearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              access.isCanRead
                ? {
                    key: '1',
                    label: (
                      <Link to={`/hardware-allocations/${record?.uuid}`}>
                        <Button className="btn-table-action-primary" type="link">
                          View
                        </Button>
                      </Link>
                    ),
                  }
                : null,
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  const createAllocationItems: MenuProps['items'] = [
    {
      label: (
        <Link to="/hardware-allocations/send">
          <SendIcon /> <span>Send Allocation</span>
        </Link>
      ),
      key: '0',
    },
    {
      label: (
        <Link to="/hardware-allocations/receive">
          <ReceiveIcon /> <span>Receive Allocation</span>
        </Link>
      ),
      key: '1',
    },
  ];

  return (
    <Spin spinning={isRefetchLoading ?? isLoading}>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4">
        <div className="tw-flex-row tw-flex tw-items-center tw-justify-between tw-gap-4">
          <Text component="h3" variant="h3">
            Allocation
          </Text>
          <div className="pagee-header-actions">
            {access.isCanCreate && currentUser.type !== 'ecoSpiritsAdmin' && (
              <Dropdown
                menu={{ items: createAllocationItems }}
                trigger={['click']}
                overlayClassName="create-allocation-dropdown"
              >
                <Space>
                  <PrimaryButton label="Create Allocation" icon={<PlusIcon />} />
                </Space>
              </Dropdown>
            )}
          </div>
        </div>
        <AllocationFilterForm
          queryParams={queryParams}
          onChange={handleFilterChange}
          refetchData={refetchData}
          setIsRefetchLoading={setIsRefetchLoading}
        />
        {!isLoading && allocationHistories.length === 0 && (
          <EmptyData title="No Report" message="All reports within your business will be displayed here." />
        )}
        {!isLoading && allocationHistories.length !== 0 && (
          <>
            <DisplayLastUpdateApp />
            <SizeBox height={8} />
            {!queryParams.viewMode ? (
              <HardwareAllocationsCardList
                data={allocationHistories}
                pagination={pagination}
                onPaginationChange={handleFilter}
              />
            ) : (
              <HardwareAllocationsTable
                rowKey="id"
                loading={isLoading}
                pagination={pagination}
                dataSource={allocationHistories}
                withAction={withAction}
                onChange2={handleFilter}
              />
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default HardwareAllocationsPage;
