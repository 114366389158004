import { Form, Spin, message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { createUser } from '../../apis/users';
import { UserI } from '../../interfaces/user';
import UserForm from '../../components/forms/user/Form';
import { CurrentUserI } from '../../interfaces/auth';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';

const UserNewPage: React.FC = () => {
  const [form] = Form.useForm();
  const currentUser: CurrentUserI = useOutletContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: createMutate, isLoading: isPageLoading } = useMutation(createUser, {
    onSuccess: async () => {
      message.info('Create user successfully!');
      queryClient.invalidateQueries({
        queryKey: ['users', 'list'],
      });
      navigate(`/users`, { replace: true });
    },
    onError: () => {
      message.error('Create user failed! Please try again');
    },
  });

  const handleSubmit = async (values: UserI) => {
    createMutate(values);
  };

  return (
    <Spin spinning={isPageLoading}>
      <div className="users-container tw-p-4">
        <CreateUpdateTopSection label="Add User" labelButton="Create" form={form} />
        <UserForm form={form} handleSubmit={handleSubmit} currentUser={currentUser} />
      </div>
    </Spin>
  );
};

export default UserNewPage;
