import React from 'react';

type Props = {
  height: number;
};

const SizeBox: React.FC<Props> = ({ height }) => {
  return <div className="size-box-component" data-testid="size-box" style={{ height }}></div>;
};

export default SizeBox;
