import React from 'react';
import { Button } from 'antd';
import { ReactSVG } from 'react-svg';
import DownloadSVG from '../svgs/download.svg';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router-dom';
import DeleteAttachmentButton from './buttons/DeleteAttachmentButton';
import { AttachmentI } from '../interfaces/attachments';
import AttachmentsTable from './tables/Attachments';

type Props = {
  attachments?: AttachmentI[];
};

const AttachmentsInDetail: React.FC<Props> = ({ attachments = [] }) => {
  const { uuid, id, serialCode } = useParams();
  const handleDownload = (record: AttachmentI) => {
    saveAs(record.url, record.fileName || 'registration-history-attachment-file');
  };

  const withAction = (record: AttachmentI) => {
    return (
      <div className="table-action-icon tw-flex tw-gap-x-1">
        <Button className="btn-table-action-primary has-icon" type="link" onClick={() => handleDownload(record)}>
          <ReactSVG src={DownloadSVG} />
        </Button>
        <DeleteAttachmentButton
          id={+record.id!}
          apiPath={`hardwares/${uuid ?? id ?? serialCode ?? '0'}`}
          accessModule="tracking"
        />
      </div>
    );
  };
  return (
    <div className="partner-history-container">
      <AttachmentsTable rowKey="id" pagination={false} dataSource={attachments} withAction={withAction} />
    </div>
  );
};

export default AttachmentsInDetail;
