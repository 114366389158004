import { initializeApp } from 'firebase/app';
import * as ANALYTICS from 'firebase/analytics';
import { TrackDashboardEventI, EventName, LoginMethod } from './models';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_ANALYTICS ?? '{}');
const analytics = ANALYTICS.getAnalytics(initializeApp(firebaseConfig));

const setUserId = (userId: number | null) => {
  return ANALYTICS.setUserId(analytics, userId ? String(userId) : null);
};

const trackEvent = (eventName: EventName, params?: object) => {
  return ANALYTICS.logEvent(analytics, eventName as string, params);
};

const trackLogin = (emailOrUsername: string) => {
  let method: LoginMethod = 'username';

  if (emailOrUsername.includes('@') && emailOrUsername.includes('.')) {
    method = 'email';
  }

  return trackEvent('login', { method });
};

const trackSearch = (query: string) => {
  return trackEvent('search', { search_term: query });
};

const trackNavigation = (pathName: string) => {
  return trackEvent('navigate', { pathName });
};

const trackScreenView = (screenName: string, id?: number | string) => {
  return trackEvent('view_screen', { screenName, id });
};

const trackFilters = (filters: Record<string, string[] | number[]>) => {
  const convertedFilters = Object.entries(filters).reduce(
    (acc, [key, value]) => {
      const stringValue = value.join(',');
      if (stringValue) acc[key] = stringValue;
      return acc;
    },
    {} as Record<string, string>,
  );
  return trackEvent('filter', convertedFilters);
};

const trackSort = (sortOption: { orderBy: string; orderType: 'asc' | 'desc' }) => {
  return trackEvent('sort', sortOption);
};

const trackClick = (label: string) => {
  return trackEvent('click', { label });
};

const trackScan = (params: { type: 'qr' | 'nfc' | 'manual'; count: number; duration: number }) => {
  return trackEvent('scan', params);
};

const trackDashboard = (params: TrackDashboardEventI) => {
  return trackEvent('performance_dashboard', params);
};

//#region hooks
// const useNavigationTrack = () => {
//   const pathname = usePathname();

//   useEffect(() => {
//     trackNavigation(pathname);
//   }, [pathname]);
// };

// const useTrackScan = (
//   type: Parameters<typeof trackScan>[0]['type'],
//   scanRef: React.MutableRefObject<Record<string, boolean>>,
// ) => {
//   // to capture the start time when user open the scan screen
//   const startTimeRef = useRef(Date.now());

//   useEffect(() => {
//     return () => {
//       EAnalytics.trackScan({
//         type,
//         count: Object.keys(scanRef.current).length,
//         duration: Date.now() - startTimeRef.current,
//       });
//     };
//   }, [type]);
// };
//#endregion

const EAnalytics = {
  trackLogin,
  setUserId,
  trackSearch,
  trackNavigation,
  trackScreenView,
  trackFilters,
  trackSort,
  trackClick,
  trackScan,
  trackDashboard,
  // useNavigationTrack,
  // useTrackScan,
};
//#endregion

export default EAnalytics;
