import React from 'react';
import { Link } from 'react-router-dom';
import { DateTimeFormat } from '../../utils/global';
import Divider from '../Divider';
import SizeBox from '../SizeBox';
import Text from '../Text';
import { CommonStatus } from '../CommonStatus';
import ViewItemOfGroupButton from '../buttons/ViewItemsOfGroup';
import { ProductHistoryI } from '../../interfaces/product';
import { displayAllProductHistoryType } from '../../utils/product';
import Avatar from '../User/Avatar';

type Props = {
  item: ProductHistoryI;
  isPlatform?: boolean;
};

const ProductHistoryItem: React.FC<Props> = ({ item, isPlatform = false }) => {
  const platformUrl = isPlatform ? '/platform' : '';

  return (
    <div className="tw-p-4">
      <div className="tw-flex tw-justify-between tw-flex-wrap tw-mb-3 tw-items-center">
        <Text variant="labelMd" className="tw-text-grey-700">
          {displayAllProductHistoryType[item?.eventName]}
        </Text>
        <Text variant="bodyMd" className="tw-text-grey-800">
          {DateTimeFormat(item?.createdAt)}
        </Text>
      </div>
      <div className="tw-flex tw-items-center tw-gap-2 tw-mb-3 tw-flex-wrap">
        {item?.relations?.user && !['pairing'].includes(item.eventName) && (
          <Avatar
            id={item?.relations?.user.id}
            className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]"
          />
        )}
        {item?.relations?.user && !['pairing'].includes(item.eventName) && (
          <Text variant="bodyMdB">{item?.relations?.user?.username}</Text>
        )}
        {item?.eventName === 'addGroup' && (
          <>
            <Text variant="bodyLg">
              Added Product to{' '}
              <span className="tw-text-primary-800">
                <Link to={`/products/groups/${item.relations?.group?.code}`}>
                  {item?.relations?.group?.title} - {item?.relations?.group?.code}
                </Link>
              </span>
            </Text>
          </>
        )}
        {item?.eventName === 'filling' && (
          <Text variant="bodyLg">
            Used product to fill in{' '}
            <Link to={`/hardware-filling/${item.relations.fillingHistory.uuid}`}>
              {item.relations?.fillingHistory?.uuid}
            </Link>{' '}
            at
            {item?.relations?.site && (
              <span className="tw-text-primary-800">
                {' '}
                <Link to={`${platformUrl}/sites/${item?.relations?.site?.id}`}>{item?.relations?.site?.legalName}</Link>
              </span>
            )}
          </Text>
        )}
        {item?.eventName === 'updateStatus' && (
          <Text variant="bodyLg">
            Changed hardware’s status from <CommonStatus status={item?.oldRecord?.status} /> to{' '}
            <CommonStatus status={item?.newRecord?.status} />
          </Text>
        )}
        {item?.eventName === 'create' && <Text variant="bodyLg">Created the product</Text>}
        {item?.eventName === 'update' && <Text variant="bodyLg">Updated the product</Text>}
        {item?.eventName === 'removeGroup' && <Text variant="bodyLg">Removed the product</Text>}
      </div>
      {item?.eventName === 'addGroup' && (
        <ViewItemOfGroupButton
          label="View All Products Within Group"
          data={item?.relations}
          drawerTitle={
            <>
              <Text
                variant="h2"
                component="h2"
                className="tw-flex tw-mb-2"
              >{`${item?.relations?.group?.title} - ${item?.relations?.group?.code}`}</Text>
              <Text variant="bodyLg" component="p" className="tw-block tw-text-grey-700 tw-font-normal">
                This product group information is accurate as of {DateTimeFormat(item?.createdAt)}
              </Text>
            </>
          }
        />
      )}
      <SizeBox height={16} />
      <Divider />
    </div>
  );
};

export default ProductHistoryItem;
