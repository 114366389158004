import { Form, Spin, message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { createPartner } from '../../../apis/partners';
import { PartnerI } from '../../../interfaces/partner';
import { CreateUpdateTopSection } from '../../../components/shared/CreateUpdateTopSection';
import PartnerForm from '../../../components/forms/partner/Form';

const PlatformPartnerNewPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate: createMutate, isLoading: isPageLoading } = useMutation(createPartner, {
    onSuccess: async () => {
      message.info('Create partner successfully!');
      queryClient.invalidateQueries({
        queryKey: ['platform-partners', 'list'],
      });
      navigate(`/platform/partners`, { replace: true });
    },
    onError: () => {
      message.error('Create partner failed! Please try again');
    },
  });

  const handleSubmit = async (values: PartnerI) => {
    createMutate(values);
  };

  return (
    <Spin spinning={isPageLoading}>
      <div className="partners-container tw-p-4">
        <CreateUpdateTopSection label="Add Partner" labelButton="Create" form={form} />
        <PartnerForm form={form} handleSubmit={handleSubmit} />
      </div>
    </Spin>
  );
};

export default PlatformPartnerNewPage;
