import { Spin } from 'antd';
import React from 'react';
import { useQuery } from 'react-query';
import PartnerInformation from '../../components/Partner/Information';
import PartnerHistoryAndAttachment from '../../components/Partner/HistoryAndAttachment';
import { getPartnerProfile } from '../../apis/profile';
import PartnerProfileName from '../../components/PartnerProfile/Name';

const PartnerProfilePage: React.FC = () => {
  const { data: partner, isLoading } = useQuery(['partner-profile', 'detail', {}], () => getPartnerProfile());

  return (
    <Spin spinning={isLoading}>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4">
        <PartnerProfileName partner={partner} />
        <PartnerInformation partner={partner} />
        <PartnerHistoryAndAttachment data={partner} />
      </div>
    </Spin>
  );
};

export default PartnerProfilePage;
