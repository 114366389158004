import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const InformationIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height ?? 24}px`}
      viewBox="0 0 24 24"
      width={`${width ?? 24}px`}
      fill={color ?? '#3F7990'}
      data-testid="information-icon"
    >
      <g id="info" clipPath="url(#clip0_11171_53495)">
        <path
          id="Vector"
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_11171_53495">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default InformationIcon;
