import React from 'react';
import ErrorImage from '../images/Error.png';
import Text from '../components/Text';
import { Link } from 'react-router-dom';

const E401Page: React.FC = () => {
  return (
    <div className="page-404-403-container tw-bg-grey-50">
      <div className="wraper">
        <img src={ErrorImage} alt="error 401" />
        <Text variant="h2" className="tw-mb-3">
          Unauthorized!
        </Text>
        <Text variant="bodyLg">Sorry, you don’t have permission to access this page</Text>
        <Link
          to="/partner-profile"
          className="tw-mt-6 tw-p-[11px] tw-inline-block tw-border tw-border-solid tw-border-primary-500 tw-rounded-lg tw-bg-white"
        >
          <Text variant="bodyLg" className="tw-inline-flex">
            Go back to Partner Profile
          </Text>
        </Link>
      </div>
    </div>
  );
};

export default E401Page;
