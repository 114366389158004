import React, { useEffect, useState } from 'react';
import { Image } from 'antd';
import DefaultSVG from '../../svgs/supervised_user_circle.svg';
import { UserI } from '../../interfaces/user';
import { getUserAvatar } from '../../apis/users';
import { getPartnerAvatar } from '../../apis/partners';
import { getImageSrc } from '../../utils/image';

type Props = {
  id: UserI['id'];
  className?: string;
  rootClassName?: string;
  type?: 'user' | 'partner';
};

const Avatar: React.FC<Props> = ({ id, className, type = 'user', rootClassName }) => {
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    const fetchImage = async (id: UserI['id']) => {
      try {
        let response;
        if (type === 'user') {
          response = await getUserAvatar(id);
        } else {
          response = await getPartnerAvatar(id);
        }
        setImageData(response?.data);
      } catch (error: any) {
        console.error('Error fetching image:', error?.message);
      }
    };
    if (id) fetchImage(id);
  }, [id]);

  return (
    <Image
      src={imageData ? getImageSrc(imageData) : ''}
      alt="Fetched Image"
      fallback={DefaultSVG}
      preview={false}
      className={`${className} ${type}`}
      loading="lazy"
      rootClassName={rootClassName}
    />
  );
};

export default Avatar;
