import React from 'react';
import ErrorImage from '../images/Error.png';
import { Link } from 'react-router-dom';
import Text from '../components/Text';

const E404Page: React.FC = () => {
  return (
    <div className="page-404-403-container tw-bg-grey-50">
      <div className="wraper">
        <img src={ErrorImage} alt="error 404" />
        <h2>Page not Found!</h2>
        <p>Sorry, the page you’re looking for might have been removed or is temporarily unavailable.</p>
        <Link
          to="/partner-profile"
          className="tw-mt-6 tw-p-[11px] tw-inline-block tw-border tw-border-solid tw-border-primary-500 tw-rounded-lg tw-bg-white"
        >
          <Text variant="bodyLg" className="tw-inline-flex">
            Go back to Partner Profile
          </Text>
        </Link>
      </div>
    </div>
  );
};

export default E404Page;
