import React, { useRef, useState } from 'react';
import Barcode from 'react-barcode';
import SecondaryButton from '../buttons/SecondaryButton';
import Icon from '../Icon';
import './DisplayBarCode.scss';
import { Modal } from 'antd';

type Props = {
  data: string;
  downloadName?: string;
};

export const DisplayBarCode: React.FC<Props> = ({ data, downloadName }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const canvasRef = useRef<any>(null);

  const handleDownload = () => {
    if (canvasRef.current) {
      const svg = canvasRef.current.querySelector('svg');
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const url = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = url;
        link.download = `${downloadName}`;
        link.click();
      };

      const encodedSvgData = btoa(svgData);
      img.src = 'data:image/svg+xml;base64,' + encodedSvgData;
    }
  };

  return (
    <>
      <div
        className="tw-w-auto tw-border tw-border-solid tw-border-grey-300 tw-rounded tw-p-3 tw-inline-block hover:tw-shadow-simple hover:tw-cursor-pointer"
        ref={canvasRef}
      >
        <button
          className="tw-shadow-none tw-bg-white tw-border-none tw-p-0 hover:tw-cursor-pointer"
          onClick={() => setIsModalOpen(true)}
          aria-label="Barcode"
        >
          <Barcode value={data} width={1} height={45} font="Oxygen" fontSize={12} />
        </button>
        <div className="tw-flex tw-justify-center">
          <SecondaryButton
            label="Download"
            icon={<Icon name="icon-download" size={16} />}
            className="tw-py-0.5 tw-rounded"
            onClick={handleDownload}
          />
        </div>
      </div>
      <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null} width={642}>
        <div className="tw-text-center tw-my-6 barcode-container">
          <div ref={canvasRef} className="barcode-svg">
            <Barcode value={data} width={2.5} height={80} font="Oxygen" fontSize={12} />
          </div>
          <SecondaryButton
            label="Download as .PNG"
            icon={<Icon name="icon-download" size={16} />}
            className="tw-py-0.5 tw-rounded tw-inline-flex tw-mt-6"
            onClick={handleDownload}
          />
        </div>
      </Modal>
    </>
  );
};

export default DisplayBarCode;
