import { Button, Dropdown, Spin } from 'antd';
import React from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { PAGE_SIZE } from '../../constants';
import { handleFilterParams } from '../../utils/global';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import { ProductGroupI, ProductGroupSearchParamsI } from '../../interfaces/product';
import ProductGroupsCardList from '../../components/card-list/ProductGroups';
import ProductGroupsTable from '../../components/tables/ProductGroups';
import DisplayLastUpdateApp from '../../components/DisplayLastUpdate';
import SizeBox from '../../components/SizeBox';
import EmptyData from '../../components/EmptyData';
import ProductGroupsFilterForm from '../../components/forms/productGroup/Filter';

type Props = {
  queryParams: ProductGroupSearchParamsI & { viewMode?: string };
  productGroups?: ProductGroupI[];
  total?: number;
  isLoading: boolean;
  refetchData: () => void;
  setIsRefetchLoading: (value: boolean) => void;
  isRefetchLoading: boolean;
};

const ProductGroupsTabPage: React.FC<Props> = ({
  queryParams,
  productGroups,
  total,
  isLoading,
  refetchData,
  setIsRefetchLoading,
  isRefetchLoading,
}) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'product-groups');
  const navigate = useNavigate();

  const currentPage = queryParams?.page;

  const pagination: any = {
    current: currentPage,
    pageSize: queryParams.limit ?? PAGE_SIZE,
    total: total,
    showTotal: (total: string) => `${total} Items`,
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  const setQueryParams = (params: Partial<ProductGroupSearchParamsI>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilterChange = (values: ProductGroupSearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              access.isCanRead
                ? {
                    key: '1',
                    label: (
                      <Link to={`/products/groups/${record?.code}`}>
                        <Button className="btn-table-action-primary" type="link">
                          View
                        </Button>
                      </Link>
                    ),
                  }
                : null,
              access.isCanUpdate
                ? {
                    key: '2',
                    label: (
                      <Link to={`/products/groups/${record?.code}/edit`}>
                        <Button className="btn-table-action-primary" type="link">
                          Edit
                        </Button>
                      </Link>
                    ),
                  }
                : null,
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <Spin spinning={isRefetchLoading ?? isLoading}>
      <div className="tw-flex tw-flex-col tw-gap-4">
        <ProductGroupsFilterForm
          queryParams={queryParams}
          onChange={handleFilterChange}
          refetchData={refetchData}
          setIsRefetchLoading={setIsRefetchLoading}
        />
        {!isLoading && productGroups?.length === 0 && (
          <EmptyData
            title="No Product Group"
            message="All product group will be displayed here."
            button={{ title: 'Create Group', url: '/product-groups/new' }}
          />
        )}
        {!isLoading && productGroups?.length !== 0 && (
          <div>
            <DisplayLastUpdateApp />
            <SizeBox height={8} />
            {!queryParams.viewMode ? (
              <ProductGroupsCardList
                access={access}
                data={productGroups ?? []}
                pagination={pagination}
                onPaginationChange={handleFilter}
              />
            ) : (
              <ProductGroupsTable
                rowKey="id"
                loading={isLoading}
                pagination={pagination}
                dataSource={productGroups}
                withAction={withAction}
                onChange2={handleFilter}
              />
            )}
          </div>
        )}
      </div>
    </Spin>
  );
};

export default ProductGroupsTabPage;
