import { BaseResponse } from '../interfaces';
import {
  ConfigReportsCollectionI,
  ConfigReportsI,
  ConfigurationI,
  ConfigurationsCollectionI,
  ConfigurationSearchParamsI,
} from '../interfaces/configuration';
import { HardwareScannedStateI, HardwareVerifyCollectionI } from '../interfaces/hardware';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetConfiguration = (code?: string) => {
  const { data: response, error, isLoading } = useFetch<any>(`configurations/${code}`);
  const data: ConfigurationI = response?.data;
  return { data, error, isLoading };
};

export const useGetConfigReport = (code?: string) => {
  const { data: response, error, isLoading } = useFetch<any>(`configurations/reports/${code}`);
  const data: ConfigReportsI = response?.data;
  return { data, error, isLoading };
};

export const getConfigurationDetails = async (code?: string) => {
  const { data: response } = await api.get<BaseResponse<ConfigurationI>>(`configurations/${code}`);
  return response?.data;
};

export const getConfigurationsList = async (params?: ConfigurationSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<ConfigurationsCollectionI>>(
    `configurations?${handleFilterParams(params)}`,
  );
  return response?.data;
};

export const createConfigurations = async (values: Partial<ConfigurationI>) => {
  const { data: response }: any = await api.post('configurations', values);
  return response.data;
};

export const updateConfiguration = async ({ code, values }: { code: string; values: Partial<ConfigurationI> }) => {
  const { data: response }: any = await api.patch(`configurations/${code}`, values);
  return response.data;
};

export const doVerifyConfiguration = async (params: {
  hardwareList: HardwareScannedStateI[];
  siteId: number;
  hardwareType: string;
}) => {
  const { data: response }: any = await api.post<BaseResponse<HardwareVerifyCollectionI>>(
    `configurations/verify`,
    params,
  );
  return response.data;
};

export const getAppliedConfigsList = async (params?: ConfigurationSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<ConfigReportsCollectionI>>(
    `configurations/reports?${handleFilterParams(params)}`,
  );
  return response?.data;
};

export const doApplyConfiguration = async (values: Partial<ConfigurationI>) => {
  const { data: response }: any = await api.post('configurations/reports', values);
  return response.data;
};

export const doApplyHardwareConfiguration = async (params: { serialCode: string; hardwareConfigCode: string }) => {
  const { data: response }: any = await api.post<BaseResponse<any>>(`configurations/apply-hardware-config`, params);
  return response.data;
};

export const updateHardwareConfiguration = async (params: { serialCode: string; values: Partial<ConfigurationI> }) => {
  const { data: response }: any = await api.post(
    `configurations/manual-update-config/${params.serialCode}`,
    params.values,
  );
  return response.data;
};

export const updateConfigurationReport = async ({ id, values }: { id: string; values: Partial<ConfigurationI> }) => {
  const { data: response }: any = await api.patch(`configurations/reports/${id}`, values);
  return response.data;
};
