import { ProductGroupVerifyCollectionI, ProductGroupVerifyItemI } from '../interfaces/product';

export const removeProductGroupVerifiedData = (
  verifiedData?: ProductGroupVerifyCollectionI,
  deleteItem: string = '',
) => {
  if (!verifiedData) return undefined;
  let products: ProductGroupVerifyItemI[] = [];
  verifiedData?.productList?.forEach((item) => {
    if (deleteItem !== item.sku) {
      products = [...products, item];
    }
  });

  const total: number = products.length ?? 0;
  let invalidNo: number = 0;
  let validNo: number = 0;
  products.forEach((item) => {
    if (item?.error) {
      invalidNo++;
    }
  });
  validNo = total - invalidNo;

  return {
    productList: products,
    numberOfProductScanned: total,
    numberOfInvalid: invalidNo,
    numberOfValid: validNo,
  };
};

export const displayAllProductHistoryType: Record<string, string> = {
  filling: 'Fill',
  recallReportUpdateStatus: 'Traceability Report',
  create: 'Product Creation',
  addGroup: 'Product Group',
  removeGroup: 'Product Group',
  update: 'Product Update',
  recallReport: 'Recall Report',
};

export const allProductHistoryTypeFilter: Record<string, string> = {
  filling: 'Fill',
  create: 'Product Creation',
  addGroup: 'Product Group',
  update: 'Product Update',
};
