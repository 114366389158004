import React, { ReactNode } from 'react';
import './Notice.scss';
import { Alert } from 'antd';
import InformationIcon from '../svgs/InformationIcon';
import ErrorIcon from '../svgs/ErrorIcon';
import WarningIcon from '../svgs/WarningIcon';

interface Props {
  message?: string | ReactNode;
  description?: string | ReactNode;
  type: 'info' | 'warning' | 'error' | 'success';
  icon?: ReactNode;
  showIcon?: boolean;
  closable?: boolean;
  className?: string;
  iconSize?: number;
  onClose?: () => void;
}

export const Notice: React.FC<Props> = ({
  message,
  description,
  type = 'info',
  icon,
  showIcon,
  closable,
  className,
  iconSize,
  onClose,
}) => {
  const renderIcon = (type: string) => {
    switch (type) {
      case 'error':
        return <ErrorIcon width={iconSize} height={iconSize} />;
      case 'warning':
        return <WarningIcon width={iconSize} height={iconSize} />;
      case 'success':
        return <InformationIcon width={iconSize} height={iconSize} />;
      default:
        return <InformationIcon width={iconSize} height={iconSize} />;
    }
  };

  return (
    <Alert
      message={message ?? message}
      description={description}
      type={type ?? 'info'}
      showIcon={showIcon ?? true}
      icon={icon ?? renderIcon(type)}
      className={`notice-custom ${type} ${className}`}
      closable={closable ?? true}
      onClose={onClose ?? onClose}
    />
  );
};
