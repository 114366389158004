import React, { useState } from 'react';
import { HardwareProgressStepListI } from '../../interfaces';
import SharedHardwareProgressBar from '../../components/shared/Hardware/ProgressBar';
import useNavigateAway from '../../hooks/useNavigateAway';
import {
  ProductGroupScannedStateI,
  ProductGroupVerifyCollectionI,
  ProductGroupVerifyItemI,
} from '../../interfaces/product';
import { doVerifyProductGroup } from '../../apis/products';
import ProductGroupScanStep from '../../components/ProductGroup/ScanStep';
import ProductGroupVerifyStep from '../../components/ProductGroup/VerifyStep';
import ProductGroupReviewStep from '../../components/ProductGroup/ReviewStep';
import ProductGroupVerifyFilterForm from '../../components/forms/productGroup/VerifyFilter';
import { removeProductGroupVerifiedData } from '../../utils/product';
import { useNavigate } from 'react-router-dom';

const progressStepList: HardwareProgressStepListI[] = [
  {
    step: 1,
    label: 'Scan',
  },
  {
    step: 2,
    label: 'Verify',
  },
  {
    step: 3,
    label: 'Review',
  },
];

const ProductGroupNewPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [scannedData, setScannedData] = useState<ProductGroupScannedStateI[]>([]);
  const [verifiedData, setVerifiedData] = useState<ProductGroupVerifyCollectionI>();
  const [filtered, setFiltered] = useState<ProductGroupVerifyCollectionI>();
  const [stopDecodingScanner, setStopDecodingScanner] = useState<boolean>(false);
  const [finalScannedData, setFinalScannedData] = useState<ProductGroupScannedStateI[]>([]);

  useNavigateAway({
    when: finalScannedData.length > 0 || scannedData.length > 0,
    handleProceed() {
      setScannedData([]);
      setFinalScannedData([]);
      setStopDecodingScanner(true);
    },
  });

  const handleVerify = async () => {
    try {
      const finalData = [...finalScannedData, ...scannedData];
      const params = {
        productList: finalData,
      } as {
        productList: ProductGroupScannedStateI[];
      };
      const data: ProductGroupVerifyCollectionI = await doVerifyProductGroup(params);
      setVerifiedData(data);
      setCurrentStep(2);
      if (data) {
        const newList = data.productList?.map((item: ProductGroupVerifyItemI) => {
          return { sku: item.sku };
        });
        setFinalScannedData(newList);
      }
      setScannedData([]);
    } catch (error) {
      console.log('Could not do verify. Please try again');
    }
  };

  const handleVerifyFilter = (values: { sku: ProductGroupScannedStateI['sku'] }) => {
    if (values?.sku) {
      const productsList = verifiedData?.productList?.filter(
        (item: ProductGroupScannedStateI) => item.sku === values.sku,
      );
      const newData: any = { ...verifiedData };
      newData.productsList = productsList;
      setFiltered(newData);
    } else {
      setFiltered(undefined);
    }
  };

  const handleDeleteVerifiedData = (item: any) => {
    const result = removeProductGroupVerifiedData(verifiedData, item.sku);
    setVerifiedData(result);
    setFinalScannedData((prevItems) => {
      const index = finalScannedData.findIndex((data) => data.sku === item.sku);
      if (index !== -1) {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      }
      return prevItems;
    });
  };

  const handleScanMoreButton = () => {
    setCurrentStep(1);
    setStopDecodingScanner(false);
  };

  const backFunction = () => {
    if (currentStep !== 1) setCurrentStep(currentStep - 1);
    if (currentStep === 1) navigate(-1);
  };

  const handleDiscard = () => {
    setScannedData([]);
    setFinalScannedData?.([]);
  };

  return (
    <div className="tw-p-4">
      <SharedHardwareProgressBar
        currentStep={currentStep}
        title="Product Grouping"
        stepList={progressStepList}
        backFunction={backFunction}
      />
      {currentStep === 2 && (
        <ProductGroupVerifyFilterForm
          handleVerifyFilter={handleVerifyFilter}
          numberOfProductsScanned={verifiedData?.numberOfProductScanned}
          numberOfInvalid={verifiedData?.numberOfInvalid}
        />
      )}
      {currentStep === 1 && (
        <ProductGroupScanStep
          setCurrentStep={setCurrentStep}
          setScannedData={setScannedData}
          scannedData={scannedData}
          finalScannedData={finalScannedData}
          handleVerify={handleVerify}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          handleDiscard={handleDiscard}
        />
      )}
      {currentStep === 2 && (
        <ProductGroupVerifyStep
          setCurrentStep={setCurrentStep}
          verifiedData={filtered ?? verifiedData}
          handleDeleteVerifiedData={handleDeleteVerifiedData}
          handleScanMoreButton={handleScanMoreButton}
        />
      )}
      {currentStep === 3 && (
        <ProductGroupReviewStep
          setCurrentStep={setCurrentStep}
          verifiedData={verifiedData}
          setScannedData={setScannedData}
          action="create"
          setFinalScannedData={setFinalScannedData}
          setVerifiedData={setVerifiedData}
        />
      )}
    </div>
  );
};

export default ProductGroupNewPage;
