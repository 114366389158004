import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const ErrorIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height ?? 16}px`}
      viewBox="0 0 16 16"
      width={`${width ?? 16}px`}
      fill={color ?? '#B13939'}
      data-testid="error-icon"
    >
      <g id="error">
        <path
          id="Vector"
          d="M8.00016 1.33398C4.32016 1.33398 1.3335 4.32065 1.3335 8.00065C1.3335 11.6807 4.32016 14.6673 8.00016 14.6673C11.6802 14.6673 14.6668 11.6807 14.6668 8.00065C14.6668 4.32065 11.6802 1.33398 8.00016 1.33398ZM8.66683 11.334H7.3335V10.0007H8.66683V11.334ZM8.66683 8.66732H7.3335V4.66732H8.66683V8.66732Z"
          fill={color ?? '#B13939'}
        />
      </g>
    </svg>
  );
};
export default ErrorIcon;
