import { Badge, Dropdown, Form, Input, MenuProps } from 'antd';
import React, { useMemo } from 'react';
import IconButton from '../../buttons/IconButton';
import SearchInputIcon from '../../../svgs/SearchInputIcon';
import FilterContent from './FilterContent';
import FilterIcon from '../../../svgs/FilterIcon';
import ViewColumn from '../../../svgs/ViewColumn';
import { EmailListSearchParamsI } from '../../../interfaces/emailList';
import { renderSortList } from '../../../utils/sort';
import DropdownApp from '../../Dropdown';
import Icon from '../../Icon';
import RefreshButton from '../../buttons/RefreshButton';

type Props = {
  queryParams: EmailListSearchParamsI & { viewMode?: string };
  onChange(newParams: EmailListSearchParamsI & { viewMode?: string }): void;
  showView?: boolean;
  showSort?: boolean;
  showFilter?: boolean;
  refetchData?: () => void;
  setIsRefetchLoading?: (loading: boolean) => void;
};

const EmailListsFilterForm: React.FC<Props> = ({
  queryParams,
  onChange,
  showView,
  showSort,
  showFilter,
  refetchData,
  setIsRefetchLoading,
}) => {
  const [form] = Form.useForm();

  const handleOnChange = (params: Partial<EmailListSearchParamsI & { viewMode?: string }>) => {
    onChange({ ...queryParams, ...params });
  };

  const count = useMemo(() => {
    if (!queryParams.filter) return 0;
    let count = 0;

    Object.values(queryParams.filter).forEach((val: any) => {
      count += val?.length || 0;
    });
    return count;
  }, [queryParams]);

  const handleDropdownItemClick: MenuProps['onClick'] = (e: any) => {
    const { key } = e;
    const [orderBy, type] = key.split('-');
    handleOnChange({
      orderBy: orderBy as EmailListSearchParamsI['orderBy'],
      orderType: type as EmailListSearchParamsI['orderType'],
    });
  };

  const items: MenuProps['items'] = renderSortList(queryParams, 'username');

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <div className="tw-flex tw-flex-row tw-gap-2 tw-w-full">
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={({ name }) => handleOnChange({ search: { name } })}
        scrollToFirstError
        className="tw-w-full"
      >
        <Form.Item
          name="name"
          className="search-box tw-w-full tw-mb-0"
          initialValue={typeof queryParams.search === 'string' ? queryParams.search : ''}
        >
          <Input placeholder="Search" prefix={<SearchInputIcon />} style={{ width: '100%' }} />
        </Form.Item>
      </Form>
      {showSort && (
        <DropdownApp
          icon={<Icon name="icon-sort" size={22} />}
          menu={menuDropdownProps}
          size="small"
          placement="bottomRight"
          type="secondary"
        />
      )}
      {showFilter && (
        <Dropdown
          className="filter-dropdown-button"
          dropdownRender={() => (
            <FilterContent
              queryParams={queryParams}
              onChange={({ filter, search }) => handleOnChange({ filter, search })}
            />
          )}
          trigger={['click']}
          overlayClassName="filter-drowdown"
        >
          <Badge count={count}>
            <IconButton icon={<FilterIcon height={20} />} />
          </Badge>
        </Dropdown>
      )}
      {showView && (
        <IconButton
          className={
            queryParams.viewMode === 'list'
              ? '!tw-h-full tw-bg-primary-800 tw-text-white hover:!tw-bg-primary-800 hover:!tw-text-white'
              : '!tw-h-full tw-bg-white hover:!tw-bg-primary-800 hover:!tw-text-white'
          }
          icon={<ViewColumn />}
          onClick={() => handleOnChange({ viewMode: queryParams.viewMode ? undefined : 'list' })}
        />
      )}
      {refetchData && <RefreshButton doRefetch={refetchData} setIsLoading={setIsRefetchLoading} />}
    </div>
  );
};

export default EmailListsFilterForm;
