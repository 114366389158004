import React, { useState } from 'react';
import { PartnerI } from '../../../interfaces/partner';
import { Button, Dropdown, Modal, Pagination, Tag } from 'antd';
import { PartnerStatus } from '../../../pages/Partners/PartnerStatus';
import { Link, useNavigate } from 'react-router-dom';
import MoreActionIcon from '../../../svgs/MoreActionIcon';
import { AccessControlI } from '../../../interfaces';
import Text from '../../Text';
import Divider from '../../Divider';
import useImpersonation from '../../Impersonation/useImpersonation';
import Icon from '../../Icon';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import { UserI } from '../../../interfaces/user';
import Avatar from '../../User/Avatar';

interface Props {
  access: AccessControlI;
  impersonateAccess: AccessControlI;
  data: PartnerI[];
  pagination: any;
  onPaginationChange: (params: { page: number; per_page: number }) => void;
}
const PlatformPartnersCardList: React.FC<Props> = ({
  access,
  impersonateAccess,
  data,
  pagination,
  onPaginationChange,
}) => {
  const navitate = useNavigate();
  const { impersonate, isLoading, isImpersonating } = useImpersonation();
  const [impersonatedEmail, setImpersonatedEmail] = useState<string>();

  const handleCardClick = (item: PartnerI) => {
    if (access.isCanRead) navitate(`/platform/partners/${item.id}`);
  };

  return (
    <section className="tw-flex tw-flex-col">
      <div className="card-list">
        {data.map((item) => (
          <div
            key={item?.id}
            className="card-item"
            onClick={() => handleCardClick(item)}
            onKeyDown={() => handleCardClick(item)}
          >
            <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between">
              <div>
                <div
                  style={{
                    width: '100%',
                    aspectRatio: '1/1',
                    backgroundColor: '#E3E3E3',
                    borderRadius: 8,
                    display: 'grid',
                    placeItems: 'center',
                    position: 'relative',
                  }}
                >
                  <Avatar
                    id={item?.id}
                    type="partner"
                    className="tw-max-h-[218px] tw-rounded-lg"
                    rootClassName="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
                  />
                  <button
                    className="tw-border-none tw-bg-grey-400 tw-rounded-sm tw-w-[30px] tw-h-[20px]"
                    style={{ position: 'absolute', right: 16, top: 16 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Dropdown
                      menu={{
                        items: [
                          access.isCanRead
                            ? {
                                key: '1',
                                label: (
                                  <Link to={`/platform/partners/${item.id}`}>
                                    <Button className="btn-table-action-primary" type="link">
                                      View
                                    </Button>
                                  </Link>
                                ),
                              }
                            : null,
                          access.isCanUpdate
                            ? {
                                key: '2',
                                label: (
                                  <Link to={`/platform/partners/${item.id}/edit`}>
                                    <Button className="btn-table-action-primary" type="link">
                                      Edit Profile
                                    </Button>
                                  </Link>
                                ),
                              }
                            : null,
                          impersonateAccess.isCanCreate && !item.isEcoSpirit
                            ? {
                                key: '3',
                                label: (
                                  <Button
                                    disabled={isImpersonating}
                                    className="btn-table-action-primary hover:tw-bg-white"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      const admin = item.users?.find((user: UserI) => user?.type === 'partnerAdmin');
                                      if (admin) setImpersonatedEmail(admin.email);
                                    }}
                                  >
                                    Impersonate
                                  </Button>
                                ),
                              }
                            : null,
                        ],
                      }}
                    >
                      <div>
                        <MoreActionIcon />
                      </div>
                    </Dropdown>
                  </button>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-2 tw-mt-3 tw-pb-3">
                  <Text variant="h6" className="tw-text-primary-800">
                    {item.displayName}
                  </Text>
                  <div className="tw-flex tw-gap-1">
                    {item.market.map((m: string) => (
                      <Tag key={m}>{m}</Tag>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <Divider />
                <div className="tw-pt-3">
                  <PartnerStatus status={item.status} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Pagination
        style={{ alignSelf: 'flex-end' }}
        total={pagination.total}
        pageSize={pagination.pageSize}
        showTotal={(total) => `${total} Items`}
        current={pagination.current}
        onChange={(page, pageSize) => onPaginationChange({ page, per_page: pageSize })}
        showSizeChanger
      />
      <Modal
        confirmLoading={isLoading}
        open={!!impersonatedEmail}
        onCancel={() => setImpersonatedEmail(undefined)}
        footer={null}
        width={412}
      >
        <div className="tw-flex tw-flex-col tw-p-4 tw-gap-8">
          <Icon name="icon-people_alt" className="tw-text-primary-800 tw-m-auto" />
          <Text variant="h3" className="tw-text-primary-800">
            Are you sure you want to impersonate this Partner’s Admin?
          </Text>
          <div className="tw-flex tw-flex-col tw-gap-4">
            <PrimaryButton
              className="tw-w-full tw-mb-3"
              label="Confirm"
              onClick={() => {
                if (impersonatedEmail) {
                  impersonate(impersonatedEmail);
                }
              }}
            />
            <SecondaryButton className="tw-w-full" label="Cancel" onClick={() => setImpersonatedEmail(undefined)} />
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default PlatformPartnersCardList;
