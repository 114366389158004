import React, { useState } from 'react';
import HeaderLayout from '../components/layouts/Header';
import MenuLayout from '../components/layouts/Menu';
import { Outlet } from 'react-router-dom';
import LogoSection from '../components/layouts/Logo';
import { Layout } from 'antd';
import useWindowResize from '../hooks/useWindowResize';
import { CurrentUserI } from '../interfaces/auth';
import MenuDrawer from '../components/layouts/MenuDrawer';
import CompanyInfo from '../components/layouts/CompanyInfo';
import ImpersontaionStatus from '../components/Impersonation/ImpersonationStatus';
const { Content, Sider } = Layout;

type Props = {
  currentUser: CurrentUserI;
};

const AdminLayout: React.FC<Props> = ({ currentUser }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);

  const { width } = useWindowResize();

  const onMenuDrawerShowClick = () => {
    setOpen(true);
  };

  return (
    <>
      <ImpersontaionStatus />
      <Layout style={{ minHeight: '100vh' }} id="admin-layout">
        {width < 992 ? (
          <MenuDrawer currentUser={currentUser} open={open} setOpen={setOpen} />
        ) : (
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            collapsedWidth={0}
            breakpoint={'lg'}
            width={220}
            reverseArrow={true}
            className={collapsed ? 'collapsed' : 'non-collapsed'}
          >
            <div className="tw-flex tw-justify-between tw-flex-col tw-top-0">
              <div>
                <LogoSection className="logo-section" />
                <MenuLayout currentUser={currentUser} />
              </div>
              <CompanyInfo />
            </div>
          </Sider>
        )}
        <Layout>
          <HeaderLayout currentUser={currentUser} onMenuDrawerShowClick={onMenuDrawerShowClick} />
          <Content>
            <Outlet context={currentUser} />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AdminLayout;
