import React, { useMemo } from 'react';
import { DashboardTypeT, HardwarePerformanceT } from '../../interfaces/dashboard';
import { Checkbox, Select } from 'antd';
import Text from '../Text';
import { EChartsOption } from 'echarts';
import ECharts from '../charts/ECharts';
import DetailsCard from './DetailsCard';
import TooltipIcon from '../buttons/TooltipIcon';
import { callDashboardEcoToteStatus, callDashboardSiteEcoToteStatus } from '../../apis/dashboard';
import { useQuery } from 'react-query';

type Props = {
  data?: HardwarePerformanceT['ecoToteStatus'];
  isLoading: boolean;
  siteId?: number;
  dashboardType?: DashboardTypeT;
  totalEcoToteTooltip: string;
  filledTooltip: string;
  partiallyTooltip: string;
  emptyTooltip: string;
};

const HardwareEcoToteStatus: React.FC<Props> = ({
  data,
  isLoading,
  siteId,
  dashboardType,
  totalEcoToteTooltip,
  filledTooltip,
  partiallyTooltip,
  emptyTooltip,
}) => {
  const [selectedProducts, setSelectedProducts] = React.useState<string[]>([]);
  const [queryParams, setQueryParams] = React.useState<{ product: string; siteId?: number }>();
  const { data: { ecoToteStatus } = {}, isLoading: isStatusLoading } = useQuery(
    ['dashboard', 'ecotote-status', queryParams],
    () => callDashboardEcoToteStatus(queryParams),
    {
      select({ data: ecoToteStatus }) {
        return { ecoToteStatus };
      },
      enabled: dashboardType !== 'site' && queryParams?.product !== undefined,
    },
  );

  const { data: { siteEcoToteStatus } = {}, isLoading: isSiteStatusLoading } = useQuery(
    ['dashboard', 'ecotote-status', queryParams],
    () => callDashboardSiteEcoToteStatus(queryParams),
    {
      select({ data: siteEcoToteStatus }) {
        return { siteEcoToteStatus };
      },
      enabled: dashboardType === 'site' && queryParams?.product !== undefined,
    },
  );

  const finalData = useMemo(() => {
    const statusData = dashboardType === 'site' ? siteEcoToteStatus : ecoToteStatus;
    return statusData ?? data;
  }, [data, ecoToteStatus, siteEcoToteStatus]);

  const pieChartOption: EChartsOption = useMemo(() => {
    const statusData = dashboardType === 'site' ? siteEcoToteStatus : ecoToteStatus;
    const finalData = statusData ?? data;
    const totalEcoTotes = finalData?.total ?? 0;
    const ecoToteFilled = finalData?.filled ?? 0;
    const ecoTotePartially = finalData?.partiallyConsumed ?? 0;
    const ecoToteEmpty = finalData?.empty ?? 0;

    return {
      tooltip: {
        show: false,
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: 'Outlets',
          type: 'pie',
          radius: ['60%', '90%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: ['{a|Total ecoTOTEs}', '{tooltipIcon|}', '{b|' + totalEcoTotes + '}'].join('\n'),
            rich: {
              a: {
                color: '#898989',
                fontSize: 16,
                lineHeight: 24,
                fontWeight: 500,
                fontFamily: 'Poppins',
              },
              tooltipIcon: {
                height: 20,
                width: 20,
                align: 'center',
                marginTop: 0,
              },
              b: {
                color: '#191919',
                fontFamily: 'Poppins',
                fontSize: 34,
                fontWeight: 500,
                lineHeight: 42,
                letterSpacing: '-0.68px',
                display: 'block',
              },
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: ecoToteFilled, name: 'Filled', itemStyle: { color: '#0483C3' } },
            { value: ecoTotePartially, name: 'Partially Consumed', itemStyle: { color: '#71C6F1' } },
            { value: ecoToteEmpty, name: 'Empty', itemStyle: { color: '#C4C4C4' } },
          ],
        },
      ],
    };
  }, [data, ecoToteStatus, siteEcoToteStatus]);

  const handleProductChange = (value: string[]) => {
    setQueryParams({ product: value.join(','), siteId: siteId ?? undefined });
    setSelectedProducts(value);
  };

  return (
    <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-p-6 tw-rounded-lg sm:tw-p-4">
      <Select
        mode="multiple"
        value={selectedProducts}
        onChange={handleProductChange}
        placeholder="All Products"
        showSearch
        optionFilterProp="children"
        className="tw-p-0 tw-w-full"
        rootClassName="product-performance-filter"
        loading={isSiteStatusLoading ?? isStatusLoading ?? isLoading}
        dropdownRender={(menu) => (
          <div className="tw-p-4">
            <Text variant="h4" className="tw-mb-1">
              Product Filter
            </Text>
            {menu}
          </div>
        )}
        optionLabelProp="label"
      >
        {finalData?.filterProducts?.map((product: any) => (
          <Select.Option key={product.sku} value={product.sku} label={product.name}>
            <span className="tw-flex tw-gap-1">
              <Checkbox checked={selectedProducts.includes(product.sku)} />
              {product.name}
            </span>
          </Select.Option>
        ))}
      </Select>
      <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-6 tw-mt-6">
        <div className="tw-flex tw-items-center tw-justify-between tw-flex-wrap sm:tw-justify-center">
          <div className="tw-relative tw-w-full tw-h-[260px] tw-max-w-[260px] sm:tw-max-w-full">
            <div className="tw-w-full tw-h-full tw-absolute tw-left-0 tw-top-[-12px] tw-flex tw-items-center tw-justify-center">
              <span className="tw-z-10 tw-h-5">
                <TooltipIcon text={totalEcoToteTooltip} iconSize={16} buttonClassName="tw-w-4 !tw-h-4" />
              </span>
            </div>
            <div className="tw-flex tw-justify-center tw-w-full tw-h-full">
              <ECharts option={pieChartOption} style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
          <div className="sm:tw-w-full tw-flex-1 tw-min-w-[150px] lg:tw-max-w-[200px]">
            <DetailsCard
              tooltipText={filledTooltip}
              percentage={finalData?.total ? (((finalData?.filled ?? 0) / finalData.total) * 100).toFixed(0) : 0}
              title="Filled"
              color="#0483C3"
              totalNumber={finalData?.filled ?? 0}
            />
            <DetailsCard
              tooltipText={partiallyTooltip}
              percentage={
                finalData?.total ? (((finalData?.partiallyConsumed ?? 0) / finalData.total) * 100).toFixed(0) : 0
              }
              title="Partially Consumed"
              color="#71C6F1"
              totalNumber={finalData?.partiallyConsumed ?? 0}
            />
            <DetailsCard
              tooltipText={emptyTooltip}
              percentage={finalData?.total ? (((finalData?.empty ?? 0) / finalData.total) * 100).toFixed(0) : 0}
              title="Empty"
              color="#C4C4C4"
              totalNumber={finalData?.empty ?? 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HardwareEcoToteStatus;
