import React from 'react';
import { Button } from 'antd';
import { ReactSVG } from 'react-svg';
import DownloadSVG from '../svgs/download.svg';
import { saveAs } from 'file-saver';
import { AttachmentI } from '../interfaces/attachments';
import AttachmentsTable from './tables/Attachments';
// import DetailFilterFormComponent from './DetailFilterForm';
import Text from './Text';
import Icon from './Icon';

type Props = {
  attachments?: AttachmentI[];
  isDisplayTitle?: boolean;
};

const Attachments: React.FC<Props> = ({ attachments = [], isDisplayTitle = true }) => {
  const handleDownload = (record: AttachmentI) => {
    saveAs(record.url, record.fileName || 'attachment-file');
  };

  const withAction = (record: AttachmentI) => {
    return (
      <div className="table-action-icon" style={{ display: 'flex' }}>
        <Button className="btn-table-action-primary has-icon" type="link" onClick={() => handleDownload(record)}>
          <ReactSVG src={DownloadSVG} />
        </Button>
      </div>
    );
  };
  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-mt-4">
      {isDisplayTitle && (
        <Text variant="labelLg" className="tw-text-primary-800 tw-flex tw-items-center tw-gap-1">
          <Icon name="icon-attach_file" /> Attachments
        </Text>
      )}
      {/* <DetailFilterFormComponent handleFilter={handleFilterSubmit} /> */}
      <AttachmentsTable rowKey="id" pagination={false} dataSource={attachments} withAction={withAction} />
    </div>
  );
};

export default Attachments;
