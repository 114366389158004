import { DateRangeT } from '../interfaces';
import dayjs from 'dayjs';
import { Countries } from 'hoangdevtrai-country-list-js';
import { ProductPerformanceI } from '../interfaces/dashboard';
import * as Flags from 'country-flag-icons/react/3x2';
import React from 'react';

type DateValueMap = {
  [date: string]: number | null;
};

type DateValueMapArrayT = {
  [date: string]: number[] | null;
};

const listDatesInRange = (dateRange: DateRangeT): string[] => {
  const {
    period: { since, until },
  } = dateRange;
  const startDate = dayjs(since);
  const endDate = dayjs(until);
  const dates: string[] = [];

  let currentDate = startDate;

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
    dates.push(currentDate.format('DD-MM-YYYY'));
    currentDate = currentDate.add(1, 'day');
  }

  return dates;
};

export const mapDataForDateRange = (
  data: DateValueMap | DateValueMapArrayT,
  dateRange: DateRangeT,
): DateValueMap | DateValueMapArrayT => {
  const mappedData: DateValueMap | DateValueMapArrayT = {};
  const dates = listDatesInRange(dateRange);

  dates.forEach((date) => {
    mappedData[date] = data[date] || null;
  });

  return mappedData;
};

export const mapCountryData = (data: ProductPerformanceI['productOutlet']['countrySites']) => {
  return data.map((item) => {
    const country = Countries.find((c) => c.value === item.countryCode);
    const CountryFlag = Flags[item.countryCode as keyof typeof Flags];
    return {
      ...item,
      countryName: country ? country.label : 'Unknown',
      countryFlag: CountryFlag ? <CountryFlag title={item.countryCode} className="country-flag tw-w-5" /> : null,
    };
  });
};
