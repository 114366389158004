import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Navigate, useLocation } from 'react-router-dom';
import { TOKEN_NAME } from './constants';
import AdminLayout from './pages/AdminLayout';
import { useGetProfile } from './apis/auth';
import { Spin } from 'antd';
import { CurrentUserI } from './interfaces/auth';
import EAnalytics from './analytics';

const NonRequireAuth: React.FC = () => {
  const location = useLocation();
  const token = Cookies.get(TOKEN_NAME);
  const profile = useGetProfile(!!token);

  const currentUserId = profile?.data?.data?.id ?? undefined;

  useEffect(() => {
    if (currentUserId) {
      EAnalytics.setUserId(currentUserId);
    }
  }, [currentUserId]);

  const handleProfileSuccess = (userInfo: CurrentUserI) => {
    return <AdminLayout currentUser={userInfo} />;
  };

  const handleProfileError = () => {
    Cookies.remove(TOKEN_NAME);
    return <Navigate to="/login" state={{ from: location }} replace />;
  };

  const handleProfileLoading = () => {
    return (
      <div className="loading-screen">
        <Spin spinning={true} />
      </div>
    );
  };

  const renderContent = () => {
    if (!token) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    if (profile.isLoading) {
      return handleProfileLoading();
    }

    if (profile.isError) {
      return handleProfileError();
    }

    if (profile.isSuccess) {
      const userInfo: CurrentUserI = profile.data.data;
      return handleProfileSuccess(userInfo);
    }

    return <Navigate to="/login" state={{ from: location }} replace />;
  };

  return renderContent();
};

export default NonRequireAuth;
