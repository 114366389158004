import { Button, Dropdown, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { PAGE_SIZE } from '../../../constants';
import { handleFilterParams } from '../../../utils/global';
import { CurrentUserI } from '../../../interfaces/auth';
import { handleAccessControl } from '../../../utils/role';
import { ProductI, ProductSearchParamsI } from '../../../interfaces/product';
import ProductsFilterForm from '../../../components/forms/product/Filter';
import ProductsTable from '../../../components/tables/Products';
import Text from '../../../components/Text';
import { getPlatformProductsList } from '../../../apis/platforms/products';
import PlatformProductsCardList from '../../../components/card-list/platforms/Products';
import DisplayLastUpdateApp from '../../../components/DisplayLastUpdate';
import EmptyData from '../../../components/EmptyData';

const PlatformProductsPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'platform/products');
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const search = urlSearch.get('search');
    const filterProductionOrigin = urlSearch.get('filter[productionOrigin]');
    const filterCategoryId = urlSearch.get('filter[categoryId]');
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      filter: { productionOrigin: filterProductionOrigin, categoryId: filterCategoryId },
      search,
    } as ProductSearchParamsI & { viewMode?: string };
  }, [urlSearch]);

  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);
  const {
    data,
    isLoading,
    refetch: refetchData,
  } = useQuery(['platform-products', 'list', queryParams], () => getPlatformProductsList(queryParams));
  const currentPage = queryParams?.page;

  const pagination: any = {
    current: currentPage,
    pageSize: queryParams.limit ?? PAGE_SIZE,
    total: data?.total,
    showTotal: (total: string) => `${total} Items`,
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  const setQueryParams = (params: Partial<ProductSearchParamsI>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilterChange = (values: ProductSearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const withAction = (record: ProductI) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              access.isCanRead
                ? {
                    key: '1',
                    label: (
                      <Link to={`/platform/products/${record?.sku}`}>
                        <Button className="btn-table-action-primary" type="link">
                          View
                        </Button>
                      </Link>
                    ),
                  }
                : null,
              access.isCanUpdate
                ? {
                    key: '2',
                    label: (
                      <Link to={`/platform/products/${record?.sku}/edit`}>
                        <Button className="btn-table-action-primary" type="link">
                          Edit
                        </Button>
                      </Link>
                    ),
                  }
                : null,
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <Spin spinning={isRefetchLoading ?? isLoading}>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4">
        <div className="tw-flex-row tw-flex tw-items-center tw-justify-between tw-gap-4">
          <Text component="h3" variant="h3">
            Products
          </Text>
        </div>
        <ProductsFilterForm
          queryParams={queryParams}
          onChange={handleFilterChange}
          refetchData={refetchData}
          setIsRefetchLoading={setIsRefetchLoading}
        />
        {!isLoading && data?.products.length === 0 && (
          <EmptyData
            title="No Product"
            message="Products within all partner entities across platform will be displayed here."
          />
        )}
        {!isLoading && data?.products.length !== 0 && (
          <>
            <DisplayLastUpdateApp />
            {!queryParams.viewMode ? (
              <PlatformProductsCardList
                access={access}
                data={data?.products ?? []}
                pagination={pagination}
                onPaginationChange={handleFilter}
              />
            ) : (
              <ProductsTable
                rowKey="id"
                loading={isLoading}
                pagination={pagination}
                dataSource={data?.products}
                withAction={withAction}
                onChange2={handleFilter}
              />
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default PlatformProductsPage;
