import React, { useMemo } from 'react';
import { Checkbox, Form } from 'antd';
import { ModuleI } from '../../../interfaces/module';
import { CheckboxOptionI } from '../../../interfaces';
import ActionSection from '../../Role/ActionSection';
import { handleGetPermissionFromModule } from '../../../utils/role';

type Props = {
  modules?: ModuleI[];
  onCheckboxChange: (values: string[], colName: string) => void;
};

const DashboardCollapse: React.FC<Props> = ({ modules, onCheckboxChange }) => {
  const productCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'ProductPerformance'),
    [modules],
  );
  const hardwareCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'HardwarePerformance'),
    [modules],
  );
  const siteCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'SitePerformance'),
    [modules],
  );

  return (
    <>
      <ActionSection
        label="Product Performance"
        content={
          <Form.Item name="colProductPerformance">
            <Checkbox.Group
              options={productCheckboxOptions}
              onChange={(e) => onCheckboxChange(e, 'colProductPerformance')}
            />
          </Form.Item>
        }
      />
      <ActionSection
        label="Hardware Performance"
        content={
          <Form.Item name="colHardwarePerformance">
            <Checkbox.Group
              options={hardwareCheckboxOptions}
              onChange={(e) => onCheckboxChange(e, 'colHardwarePerformance')}
            />
          </Form.Item>
        }
      />
      <ActionSection
        label="Site Performance"
        content={
          <Form.Item name="colSitePerformance">
            <Checkbox.Group options={siteCheckboxOptions} onChange={(e) => onCheckboxChange(e, 'colSitePerformance')} />
          </Form.Item>
        }
      />
    </>
  );
};

export default DashboardCollapse;
