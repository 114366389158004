import React, { useEffect, useMemo, useState } from 'react';
import { useGetDashboardProductFilter, useGetDashboardProductPerformance } from '../../apis/dashboard';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Notice } from '../../components/Notice';
import Text from '../../components/Text';
import { DashboardSearchParamsI } from '../../interfaces/dashboard';
import { findDateRangeOption, handleFilterParams } from '../../utils/global';
import { DateRangeT } from '../../interfaces';
import DashboardProductFilter from '../../components/forms/dashboard/ProductFilter';
import ProductTotalOutlet from '../../components/Dashboard/ProductTotalOutlet';
import ProductSustainability from '../../components/Dashboard/ProductSustainability';
import RefreshButton from '../../components/buttons/RefreshButton';
import DisplayLastUpdateApp from '../../components/DisplayLastUpdate';
import {
  dateRangeOptions,
  ProductCO2eAvoided,
  ProductOutletTransactionTooltip,
  ProductOutletWithoutTransactionTooltip,
  ProductPackagingWasteEliminated,
  ProductTotalConsumedTooltip,
  ProductTotalOutletByCountryTooltip,
  ProductTotalOutletTooltip,
  ProductTotalProductionTooltip,
} from '../../constants/dashboard';
import DashboardTotalConsumedValue from '../../components/Dashboard/TotalConsumedValue';
import DashboardProductionVolume from '../../components/Dashboard/ProductionVolume';
import EAnalytics from '../../analytics';
import { TrackDashboardEventI } from '../../analytics/models';
import { CurrentUserI } from '../../interfaces/auth';
import useWindowResize from '../../hooks/useWindowResize';
import SizeBox from '../../components/SizeBox';
import { Spin } from 'antd';

const ProductPerformance: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { width } = useWindowResize();

  const queryParams = useMemo(() => {
    const startDate = urlSearch.get('startDate');
    const endDate = urlSearch.get('endDate');
    const country = urlSearch.get('country')?.split(',');
    const product = urlSearch.get('product')?.split(',');

    return {
      startDate,
      endDate,
      country,
      product,
    } as DashboardSearchParamsI;
  }, [urlSearch]);

  useEffect(() => {
    const event: TrackDashboardEventI = {
      page: 'Product Performance',
      timestamp: new Date().toISOString(),
      partnerOwner: currentUser.username,
      payload: {
        ...queryParams,
      },
    };

    EAnalytics.trackDashboard(event);
  }, []);

  const dateRangeQuery = findDateRangeOption(
    queryParams?.startDate ?? '',
    queryParams?.endDate ?? '',
    dateRangeOptions,
  );

  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);
  const [activeDateRange, setActiveDateRange] = useState<DateRangeT>(dateRangeQuery ?? dateRangeOptions[0]);
  const { data, isLoading, refetch } = useGetDashboardProductPerformance(queryParams);
  const { data: filterData } = useGetDashboardProductFilter(queryParams);

  const setQueryParams = (params: DashboardSearchParamsI) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  return (
    <Spin spinning={isRefetchLoading ?? isLoading}>
      <div className="tw-mt-6">
        {width > 768 && (
          <>
            <div className="tw-flex tw-items-center tw-justify-center tw-px-4">
              <Notice
                type="info"
                message={
                  <Text variant="bodyMd" className="tw-text-grey-900">
                    <span className="tw-font-bold">Product Performance</span> offers insights into the performance of
                    products managed by your partnership.
                  </Text>
                }
                closable={false}
                iconSize={16}
                className="tw-mt-2 tw-mb-3 tw-flex-auto tw-mr-4 tw-w-full"
              />
              <RefreshButton
                doRefetch={refetch}
                className="tw-flex-auto !tw-w-12 !tw-h-12"
                setIsLoading={setIsRefetchLoading}
              />
            </div>
            <div className="tw-flex tw-justify-end">
              <DisplayLastUpdateApp />
            </div>
          </>
        )}
        {width < 768 && (
          <>
            <div className="tw-flex tw-justify-between tw-items-center xs:tw-flex-col xs:tw-items-start tw-mb-4 tw-px-4">
              <DisplayLastUpdateApp />
              <RefreshButton
                doRefetch={refetch}
                className="xs:tw-w-full xs:tw-mt-4"
                setIsLoading={setIsRefetchLoading}
              />
            </div>
          </>
        )}
        <div className="tw-px-4">
          <DashboardProductFilter
            setQueryParams={setQueryParams}
            setActiveDateRange={setActiveDateRange}
            activeDateRange={activeDateRange}
            dateRangeOptions={dateRangeOptions}
            filterData={filterData}
            queryParams={queryParams}
          />
        </div>
        <div className="tw-px-4">
          <div className="tw-mt-6 tw-gap-6 tw-grid tw-grid-cols-2 tw-mb-6 sm:tw-grid-cols-1">
            <DashboardProductionVolume
              data={data?.productionVolume}
              isLoading={isLoading}
              activeDateRange={activeDateRange}
              totalProductionTooltip={ProductTotalProductionTooltip}
            />
            <DashboardTotalConsumedValue
              data={data?.consumedVolume}
              isLoading={isLoading}
              activeDateRange={activeDateRange}
              totalConsumedTooltip={ProductTotalConsumedTooltip}
            />
          </div>
        </div>
        <div className="tw-px-4">
          <ProductTotalOutlet
            data={data?.productOutlet}
            isLoading={isLoading}
            activeDateRange={activeDateRange}
            totalOutletTooltip={ProductTotalOutletTooltip}
            outletTransactionTooltip={ProductOutletTransactionTooltip}
            outletWithoutTransactionTooltip={ProductOutletWithoutTransactionTooltip}
            totalOutletByCountryTooltip={ProductTotalOutletByCountryTooltip}
          />
        </div>
        <div className="tw-px-4">
          <ProductSustainability
            data={data?.sustainability}
            isLoading={isLoading}
            activeDateRange={activeDateRange}
            packagingWasteEliminated={ProductPackagingWasteEliminated}
            productCO2eAvoided={ProductCO2eAvoided}
          />
        </div>
        <SizeBox height={16} />
      </div>
    </Spin>
  );
};

export default ProductPerformance;
