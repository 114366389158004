import { Button, message, Modal, Popover, TablePaginationConfig, Tag } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { getUsersList } from '../../apis/users';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import UsersTable from '../../components/tables/Users';
import { UserI, UserSearchParamsI, UserStatusI } from '../../interfaces/user';
import Avatar from '../../components/User/Avatar';
import { RoleI } from '../../interfaces/role';
import { CommonStatus } from '../../components/CommonStatus';
import { useParams } from 'react-router-dom';
import { addEmailListExistingUsers } from '../../apis/emailLists';
import { PAGE_SIZE } from '../../constants';
import { EmailListDetailsSearchParamsI } from '../../interfaces/emailList';
import { handleFilterParams } from '../../utils/global';
import ExistingUserFilter from '../../components/forms/email-list/ExistingUserFilter';
import Text from '../../components/Text';

const AddExistingUserModal = ({
  addExistingUserModalOpen,
  setAddExistingUserModalOpen,
  emailListUsers,
}: {
  addExistingUserModalOpen: boolean;
  setAddExistingUserModalOpen: (value: boolean) => void;
  emailListUsers: { email: string }[];
}) => {
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const name = urlSearch.get('search[name]');
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      viewMode,
      search: name,
    } as EmailListDetailsSearchParamsI;
  }, [urlSearch]);
  const handleCancel = () => {
    setSelectedUserKeys([]);
    setSearchQuery('');
    setStatusFilter([]);
    setUserTablePage(1);
    setUserTableLimit(PAGE_SIZE);
    setAddExistingUserModalOpen(false);
  };
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<UserStatusI[]>([]);
  const [userTablePage, setUserTablePage] = useState(1);
  const [orderBy, setOrderBy] = useState<UserSearchParamsI['orderBy'] | undefined>(undefined);
  const [orderType, setOrderType] = useState<UserSearchParamsI['orderType'] | undefined>(undefined);
  const [userTableLimit, setUserTableLimit] = useState(PAGE_SIZE);
  const { id } = useParams();
  const [userList, setUserList] = useState<UserI[]>([]);

  const { data, isLoading } = useQuery({
    queryFn: () =>
      getUsersList({
        page: userTablePage,
        limit: userTableLimit,
        search: { username: searchQuery },
        filter: { status: statusFilter },
        orderBy,
        orderType,
      }),
    queryKey: [userTableLimit, userTablePage, searchQuery, statusFilter, id, orderBy, orderType],
  });

  const [selectedUserKeys, setSelectedUserKeys] = useState<React.Key[]>([]);

  const { mutate } = useMutation({
    mutationFn: () => addEmailListExistingUsers(+id!, { userIds: selectedUserKeys.map((id) => id as number) }),
    onSuccess: () => {
      queryClient.invalidateQueries([`email-list/${id}?${handleFilterParams(queryParams)}`]);
      message.success('New email added!');
      setSelectedUserKeys([]);
      handleCancel();
    },
  });

  const queryClient = useQueryClient();
  const columns = [
    {
      title: ' ',
      width: 60,
      dataIndex: 'id',
      render: (id: UserI['id']) => {
        return <Avatar id={id ?? 0} className="!tw-w-10 !tw-h-10 tw-rounded-[50%]" />;
      },
    },
    {
      title: 'User Name',
      width: 150,
      ellipsis: true,
      render: (user: UserI) => {
        return <span>{user?.username}</span>;
      },
    },
    {
      title: 'Roles',
      width: 150,
      render: (user: UserI) => {
        const { roles, type } = user;
        const count = roles.length;
        if (type === 'partnerAdmin') {
          return <Tag className="tw-truncate tw-max-w-[145px]">Partner Administrator</Tag>;
        }
        return (
          count > 0 && (
            <div className="tw-flex tw-items-center">
              <Tag className="tw-truncate tw-max-w-[145px]">{roles[0]?.name}</Tag>
              {count > 1 && (
                <span className="more-item-number">
                  <Popover
                    content={roles.map((role: RoleI) => (
                      <Tag key={role.id} className="tw-truncate tw-max-w-[150px]">
                        {role?.name}
                      </Tag>
                    ))}
                    trigger="click"
                  >
                    <span className="tw-cursor-pointer">{`+${count - 1}`}</span>
                  </Popover>
                </span>
              )}
            </div>
          )
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      render: (status: string) => {
        return <CommonStatus status={status} />;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 200,
      ellipsis: true,
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (selectedUserKeys.length - newSelectedRowKeys.length === 1) {
      setSelectedUserKeys(newSelectedRowKeys);
    }
    setSelectedUserKeys((prevSelected) => {
      return [...new Set([...prevSelected, ...newSelectedRowKeys])];
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedUserKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: Record<string, unknown>) => {
      if (selectedUserKeys.includes(record.id as React.Key)) {
        return {};
      }
      if (
        emailListUsers.some((emailListUser) => emailListUser.email === record.email) ||
        emailListUsers.length + selectedUserKeys.length >= 10
      ) {
        return { disabled: true };
      } else {
        return {};
      }
    },
  };

  const pagination: TablePaginationConfig = {
    current: userTablePage,
    pageSize: userTableLimit,
    total: userList?.length,
    onChange: (page, pageSize) => {
      setUserTablePage(page);
      setUserTableLimit(pageSize);
    },

    showTotal: (total) => `${selectedUserKeys.length} of ${total} selected`,
  };

  const stateQueryParams = {
    page: userTablePage,
    limit: userTableLimit,
    search: { username: searchQuery },
    filter: { status: statusFilter },
    orderBy,
    orderType,
  };

  const handleFilterChange = (values: UserSearchParamsI) => {
    setSearchQuery(values.search?.username);
    setStatusFilter(values.filter?.status as UserStatusI[]);
    setOrderBy(values.orderBy);
    setOrderType(values.orderType);
  };

  useEffect(() => {
    if (data?.data) {
      const { users } = data.data;
      const filteredUsers = users.filter(
        (user: UserI) => !emailListUsers.some((emailObj) => emailObj.email === user.email),
      );
      setUserList(filteredUsers);
    }
  }, [data?.data]);

  return (
    <Modal open={addExistingUserModalOpen} onCancel={handleCancel} footer={null} width={1000}>
      <Text variant="h2" className="tw-m-0">
        Add Existing User
      </Text>
      <ExistingUserFilter queryParams={stateQueryParams} onChange={handleFilterChange} showFilter />
      <UsersTable
        columns={columns}
        rowKey="id"
        loading={isLoading}
        rowSelection={rowSelection}
        pagination={pagination}
        dataSource={userList}
      />
      <span className="tw-flex tw-flex-row tw-w-full tw-gap-4 tw-mt-8">
        <Button type="default" className="tw-text-primary-500 tw-border-primary-500 tw-w-2/5" onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" className="tw-w-3/5" onClick={() => mutate()}>
          Add
        </Button>
      </span>
    </Modal>
  );
};

export default AddExistingUserModal;
