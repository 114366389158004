import { Spin } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUser } from '../../apis/users';
import UserName from '../../components/User/Name';
import UserInformation from '../../components/User/Information';
import UserResources from '../../components/User/Resources';

const UserDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data: user, isLoading, error } = useGetUser(Number(id));
  if (error === 'User not found!') {
    navigate('/not-allowed', { replace: true });
  }
  return (
    <Spin spinning={isLoading}>
      <div className="tw-p-4">
        <UserName user={user} />
        <UserInformation user={user} />
        <UserResources data={user} id={id} />
      </div>
    </Spin>
  );
};

export default UserDetailsPage;
