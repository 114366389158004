import { Badge, Dropdown, Form, Input, MenuProps } from 'antd';
import React, { useMemo } from 'react';
import { renderSortList } from '../../utils/sort';
import SearchInputIcon from '../../svgs/SearchInputIcon';
import DropdownApp from '../Dropdown';
import Icon from '../Icon';
import IconButton from '../buttons/IconButton';
import FilterIcon from '../../svgs/FilterIcon';
import ProductHistoryFilterContent from './FilterContent';
import { ProductHistorySearchParams } from '../../interfaces/product';
import RefreshButton from '../buttons/RefreshButton';

type Props = {
  queryParams: ProductHistorySearchParams & { viewMode?: string };
  onChange(newParams: ProductHistorySearchParams & { viewMode?: string }): void;
  refetchData: () => void;
  setIsRefetchLoading?: (value: boolean) => void;
};

const ProductHistoryFilter: React.FC<Props> = ({ queryParams, onChange, refetchData, setIsRefetchLoading }) => {
  const [form] = Form.useForm();

  const handleOnChange = (params: Partial<ProductHistorySearchParams & { viewMode?: string }>) => {
    onChange({ ...queryParams, ...params });
  };

  const count = useMemo(() => {
    if (!queryParams.filter) return 0;
    let count = 0;
    Object.values(queryParams.filter).forEach((val) => {
      const arr = val?.split(',');
      count += arr?.length || 0;
    });
    return count;
  }, [queryParams]);

  const handleDropdownItemClick: MenuProps['onClick'] = (e: any) => {
    const { key } = e;
    const [orderBy, type] = key.split('-');
    handleOnChange({
      orderBy: orderBy as ProductHistorySearchParams['orderBy'],
      orderType: type as ProductHistorySearchParams['orderType'],
    });
  };

  const items: MenuProps['items'] = renderSortList(queryParams);

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <div className="filter-form-container">
      <div className="form-wrap">
        <Form
          initialValues={{ search: queryParams.search }}
          form={form}
          requiredMark={false}
          colon={false}
          onFinish={({ search }) => handleOnChange({ search })}
          scrollToFirstError
          className="tw-w-full"
        >
          <div className="form-container">
            <div className="form-content tw-flex-auto">
              <Form.Item name="search" className="search-box tw-w-full">
                <Input placeholder="Search" prefix={<SearchInputIcon />} allowClear />
              </Form.Item>
            </div>
            <div className="tw-flex tw-gap-3 xs:tw-gap-1 xs:tw-w-full xs:tw-justify-end">
              <DropdownApp
                icon={<Icon name="icon-sort" size={22} />}
                menu={menuDropdownProps}
                size="small"
                placement="bottomRight"
                type="secondary"
              />
              <Dropdown
                className="filter-dropdown-button"
                disabled={false}
                dropdownRender={() => (
                  <ProductHistoryFilterContent
                    queryParams={queryParams}
                    onChange={({ filter, search }) => handleOnChange({ filter, search })}
                  />
                )}
                trigger={['click']}
                overlayClassName="filter-drowdown"
              >
                <Badge count={count}>
                  <IconButton icon={<FilterIcon height={20} />} disabled={false} />
                </Badge>
              </Dropdown>
              <RefreshButton doRefetch={refetchData} setIsLoading={setIsRefetchLoading} />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ProductHistoryFilter;
