import { Form, Spin, message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { createProduct } from '../../apis/products';
import { ProductI } from '../../interfaces/product';
import ProductForm from '../../components/forms/product/Form';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';

const ProductNewPage: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate: createMutate, isLoading: isPageLoading } = useMutation(createProduct, {
    onSuccess: async () => {
      message.info('Create product successfully!');
      queryClient.invalidateQueries({
        queryKey: ['products', 'list'],
      });
      navigate(`/products`, { replace: true });
    },
    onError: () => {
      message.error('Create product failed! Please try again');
    },
  });

  const handleSubmit = async (values: Partial<ProductI>) => {
    createMutate(values);
  };

  return (
    <Spin spinning={isPageLoading}>
      <div className="tw-p-4">
        <CreateUpdateTopSection label="Add Product" labelButton="Create" form={form} />
        <ProductForm form={form} handleSubmit={handleSubmit} />
      </div>
    </Spin>
  );
};

export default ProductNewPage;
