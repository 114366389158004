import React, { PropsWithChildren, ReactNode } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { CurrentUserI } from '../interfaces/auth';
import Text from './Text';
import Icon from './Icon';
import { IconName } from './Icon/iconName';

interface Props extends PropsWithChildren {
  currentUser: CurrentUserI;
}

const TabLink: React.FC<Props> & { TabItem: typeof TabItem } = ({ children, currentUser }) => {
  return (
    <>
      <div className="tw-flex tw-items-center tw-gap-6 tw-py-3 tw-border-b tw-border-t-0 tw-border-r-0 tw-border-solid tw-border-l-0 tw-border-grey-700 tw-p-4">
        {children}
      </div>
      <Outlet context={currentUser} />
    </>
  );
};

const TabItem = ({ linkTo, label, icon }: { linkTo: string; label: ReactNode; icon: IconName }) => {
  const location = useLocation();
  const isActive = location.pathname === linkTo;

  return (
    <Link
      to={linkTo}
      className={`${isActive ? "active-tab tw-text-primary-800 after:tw-content-[''] after:tw-absolute after:tw-left-0 after:tw--bottom-3 after:tw-w-full after:tw-border-b-2 after:tw-border-solid after:tw-border-primary-800 after:tw-border-b-2 after:tw-border-solid after:tw-border-[#437132]" : ''} tw-text-grey-700 hover:!tw-text-primary-800 hover:tw-opacity-[1] tw-gap-6 tw-relative`}
    >
      <Text
        variant="inputLabelLg"
        className={`tw-flex tw-items-center tw-font-normal tw-gap-2 hover:tw-font-semibold ${isActive ? 'active-tab tw-font-semibold' : ''}`}
      >
        <Icon name={icon} size={20} />
        <span>{label}</span>
      </Text>
    </Link>
  );
};

TabLink.TabItem = TabItem;

export default TabLink;
