import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { UserI } from '../../interfaces/user';
import { Popover, Tag } from 'antd';
import { CommonStatus } from '../CommonStatus';
import { RoleI } from '../../interfaces/role';
import { SiteI } from '../../interfaces/site';
import Avatar from '../User/Avatar';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const UsersTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: ' ',
      width: 60,
      dataIndex: 'id',
      render: (id: UserI['id']) => {
        return <Avatar id={id ?? 0} className="!tw-w-10 !tw-h-10 tw-rounded-[50%]" />;
      },
    },
    {
      title: 'User Name',
      width: 150,
      ellipsis: true,
      render: (user: UserI) => {
        return <span>{user?.username}</span>;
      },
    },
    {
      title: 'Roles',
      width: 150,
      render: (user: UserI) => {
        const { roles, type } = user;
        const count = roles.length;
        if (type === 'partnerAdmin') {
          return <Tag className="tw-truncate tw-max-w-[145px]">Partner Administrator</Tag>;
        }
        return (
          count > 0 && (
            <div className="tw-flex tw-items-center">
              <Tag className="tw-truncate tw-max-w-[145px]">{roles[0]?.name}</Tag>
              {count > 1 && (
                <span className="more-item-number">
                  <Popover
                    content={roles.map((role: RoleI) => (
                      <Tag key={role.id} className="tw-truncate tw-max-w-[150px]">
                        {role?.name}
                      </Tag>
                    ))}
                    trigger="click"
                  >
                    <span className="tw-cursor-pointer">{`+${count - 1}`}</span>
                  </Popover>
                </span>
              )}
            </div>
          )
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 200,
      ellipsis: true,
    },
    {
      title: 'MOBILE',
      dataIndex: 'contactNumber',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Sites',
      width: 150,
      render: (user: UserI) => {
        const { sites, type } = user;

        if (type === 'partnerAdmin') {
          return <Tag className="tw-truncate tw-max-w-[145px]">All Sites</Tag>;
        }
        const count = sites?.length;
        return (
          count > 0 && (
            <div className="tw-flex tw-items-center">
              <Tag className="tw-truncate tw-max-w-[145px]">{sites[0]?.displayName}</Tag>
              {count > 1 && (
                <span className="more-item-number">
                  <Popover
                    content={sites.map((site: SiteI) => (
                      <Tag key={site.id} className="tw-truncate tw-max-w-[150px]">
                        {site?.displayName}
                      </Tag>
                    ))}
                    trigger="click"
                  >
                    <span className="tw-cursor-pointer">{`+${count - 1}`}</span>
                  </Popover>
                </span>
              )}
            </div>
          )
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      render: (status: string) => {
        return <CommonStatus status={status} />;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default UsersTable;
