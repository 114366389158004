import { Form, message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import RoleForm from '../../components/forms/role/Form';
import { updateRole, useGetRole } from '../../apis/roles';
import { CurrentUserI } from '../../interfaces/auth';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';

const RoleUpdatePage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();
  const { data: role, isLoading } = useGetRole(Number(id));

  const { mutateAsync: updateMutate, isLoading: isUpdateLoading } = useMutation(updateRole, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['roles', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`roles/${id}`, undefined],
      });
      queryClient.invalidateQueries();
      message.info('Update role successfully!');
      navigate(`/roles`, { replace: true });
    },
    onError: () => {
      message.error('Update role failed! Please try again');
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  return (
    <Spin spinning={isLoading || isUpdateLoading}>
      <div className="role-update-container tw-p-4">
        <CreateUpdateTopSection label="Edit Role" labelButton="Update" form={form} />
        <RoleForm form={form} handleSubmit={handleSubmit} role={role} currentUser={currentUser} />
      </div>
    </Spin>
  );
};

export default RoleUpdatePage;
