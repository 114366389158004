import React from 'react';
import './Information.scss';
import Divider from '../Divider';
import { HardwareFillingHistoryI } from '../../interfaces/hardwareFilling';
import { DateTimeFormat, TimeFormat } from '../../utils/global';
import { Tag } from 'antd';
import DetailsView from '../DetailsView';
import { handleGetUniqType } from '../../utils/hardware';
import Text from '../Text';
import Icon from '../Icon';
import { Link } from 'react-router-dom';

type Props = {
  data?: HardwareFillingHistoryI;
};

const FillingHistoryInformation: React.FC<Props> = ({ data }) => {
  return (
    <div className="filling-history-information-container details-container">
      <DetailsView title="Filling Details">
        <div className="flex-row">
          <DetailsView.Field label="Date Created" value={DateTimeFormat(data?.createdAt)} />
        </div>
        <div className="flex-row">
          <DetailsView.Field label="Filling Start Time" value={TimeFormat(data?.fillingStartTime)} />
          <DetailsView.Field label="Filling End Time" value={TimeFormat(data?.fillingEndTime)} />
        </div>
        <Divider />
        <div className="flex-row">
          <DetailsView.Field label="Product SKU" value={data?.product?.sku} />
          <DetailsView.Field label="Product Name" value={data?.product?.name} />
        </div>
        <Divider />
        <div className="flex-row">
          <DetailsView.Field label="Lot Number" value={data?.lotNumber} />
        </div>
        <div className="flex-row">
          <DetailsView.Field label="Total Incoming Liquid (L)" value={data?.barrelVolume} />
          <DetailsView.Field label="Unused Partial Litres (L)" value={data?.primingVolume} />
        </div>
        <Divider />
        <div className="flex-row">
          <DetailsView.Field label="Number of ecoTOTEs" value={data?.successHardwareCount} />
          <DetailsView.Field
            label="Hardware Type"
            value={handleGetUniqType(data?.hardwareList ?? []).map((item: { type: string; count: number }) => (
              <>
                {item.type.includes('ecoTOTE') && (
                  <span className="tw-flex tw-gap-2 tw-items-center">
                    <Text variant="bodyLgB" className="tw-min-w-[24px]">
                      {item.count}
                    </Text>
                    <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                      <Icon name="icon-eco-tote" size={20} /> <span>{item.type}</span>
                    </Text>
                  </span>
                )}
                {item.type.includes('ecoPLANT') && (
                  <span className="tw-flex tw-gap-2 tw-items-center">
                    <Text variant="bodyLgB" className="tw-min-w-[24px]">
                      {item.count}
                    </Text>
                    <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                      <Icon name="icon-eco-plant" size={20} /> <span>{item.type}</span>
                    </Text>
                  </span>
                )}
                {item.type.includes('SmartPour') && (
                  <span className="tw-flex tw-gap-2 tw-items-center">
                    <Text variant="bodyLgB" className="tw-min-w-[24px]">
                      {item.count}
                    </Text>
                    <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                      <Icon name="icon-smart-pour" size={20} /> <span>{item.type}</span>
                    </Text>
                  </span>
                )}
              </>
            ))}
          />
        </div>
        <Divider />
        <div className="flex-row">
          <DetailsView.Field
            label="ecoPLANT"
            value={<Link to={`/tracking/${data?.ecoPlant?.serialCode}`}>{data?.ecoPlant?.serialCode}</Link>}
          />
          {data?.site && (
            <DetailsView.Field
              label="Site"
              value={
                <Tag>
                  <Link to={`/sites/${data?.site?.id}`}>{data?.site?.displayName}</Link>
                </Tag>
              }
            />
          )}
        </div>
        {/* <DetailsView.Field label="Remarks" value={data?.remarks} /> */}
      </DetailsView>
    </div>
  );
};

export default FillingHistoryInformation;
