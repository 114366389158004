import { DateRangeT } from '../interfaces';
import dayjs from 'dayjs';

const today = dayjs().startOf('day').toDate();

export const dateRangeOptions: DateRangeT[] = [
  {
    title: 'Last 7 days',
    alias: 'last7days',
    period: {
      since: dayjs().subtract(6, 'day').startOf('day').toDate(),
      until: today,
    },
  },
  {
    title: 'Last 30 days',
    alias: 'last30days',
    period: {
      since: dayjs().subtract(29, 'day').startOf('day').toDate(),
      until: today,
    },
  },
  {
    title: 'This month',
    alias: 'thismonth',
    period: {
      since: dayjs().startOf('month').toDate(),
      until: today,
    },
  },
  {
    title: 'This year',
    alias: 'thisyear',
    period: {
      since: dayjs().startOf('year').toDate(),
      until: today,
    },
  },
  {
    title: 'Custom Range',
    alias: 'custom',
    period: {
      since: dayjs().startOf('year').toDate(),
      until: dayjs().startOf('year').add(31, 'day').toDate(),
    },
  },
];

export const ProductTotalProductionTooltip =
  '<b>Total Production (L)</b> refers to the volume of selected managed products added via fills from ecoPLANT(s) during the selected time period.';
export const ProductTotalConsumedTooltip =
  '<b>Total Consumed Volume (L)</b> refers to the volume of selected managed products consumed via depletion, priming, and dispense from ecoTOTE(s) and/or SmartPour(s) during the selected time period.';
export const ProductTotalOutletTooltip =
  '<b>Total Outlet</b> refers to the number of outlets that have received, depleted, or dispensed the selected managed product(s) historically.';
export const ProductOutletTransactionTooltip =
  '<b>Outlet with Transaction</b> refers to the number of outlets that have received, depleted, or dispensed the selected managed product(s) within the selected time period.';
export const ProductOutletWithoutTransactionTooltip =
  '<b>Outlet without Transaction</b> refers to the number of outlets that have not received, depleted, or dispensed the selected managed product(s) within the selected time period.';
export const ProductTotalOutletByCountryTooltip =
  '<b>Total Outlet by Country</b> refers to the breakdown count of outlets with and without transactions by country for the selected managed product(s) within the selected time period.';
export const ProductProductByCountryTooltip = 'refers to the total volume in circulation of each managed product(s).';
export const ProductProductbyCurrentVolume =
  '<b>Current Volume</b> refers to the total volume in circulation of each managed product(s).';
export const ProductPackagingWasteEliminated =
  '<b>Packaging Waste Elminated</b> refers to the amount packaging waste estimated to have been eliminated during the selected period from managed product(s).';
export const ProductCO2eAvoided =
  '<b>CO2e Avoided (kg)</b> refers to the amount carbon dioxide emission estimated to have been avoided during the selected period from managed product(s).';

export const HardwareTotalHardwareTooltip =
  '<b>Total Hardware</b> refers to the total number of managed hardware of all hardware types.';
export const HardwareFilledTooltip = '<b>Filled</b> refers to the number of managed ecoTOTE(s) at full capacity.';
export const HardwarePartiallyTooltip =
  '<b>Partially Consumed</b> refers to the number of managed ecoTOTE(s) that has been partially depleted.';
export const HardwareEmptyTooltip =
  '<b>Empty</b> refers to the number of managed ecoTOTE(s) that has been fully depleted.';
export const HardwareTotalProductInEcoToteTooltip =
  '<b>Total Products in ecoTOTE</b> refers to the total number of products currently held in owned ecoTOTE(s).';
export const HardwareTotalProductionTooltip =
  '<b>Total Production</b> refers to the volume of selected products added via fills from managed ecoPLANT during the selected time period.';
export const HardwareEcoToteInteractionTooltip =
  '<b>ecoTOTE with Interactions</b> refers to the number of managed ecoTOTE(s) with interactions during the selected time period.';
export const HardwareHardwareTypeTooltip =
  '<b>Hardware Types</b> shows the breakdown count for each hardware type owned.';
export const HardwareTotalEcoToteTooltip = '<b>Total ecoTOTEs</b> refers to the total number of owned ecoTOTE(s).';
export const HardwareProductByCountryTooltip =
  'refers to the total volume in circulation of each product(s) currently held in owned ecoTOTE(s).';
export const HardwareTotalConsumedTooltip =
  '<b>Total Consumed Volume (L)</b> refers to the volume of selected managed products consumed via depletion, priming, and dispense from owned ecoTOTE(s) and/or owned SmartPour(s) during the selected time period.';
export const HardwareEcoToteInteractionsByCountryTooltip =
  '<b>ecoTOTE with Interactions by Country</b> refers to the number of ecoTOTE(s) that has been delivered, received, depleted, or mated with, by country, during the selected time period.';
export const HardwareProductbyCurrentVolume =
  '<b>Current Volume</b> refers to the total volume in circulation of each product(s) currently held in owned ecoTOTE(s).';

export const SiteTotalProductionTooltip =
  '<b>Total Production (L)</b> refers to the volume of selected products added via fills from ecoPLANT(s) or via receipt of ecoTOTE(s) deliveries, at the selected site, during the selected time period.';
export const SiteTotalHardwareTooltip =
  '<b>Total Hardware</b> refers to the total number of hardware, at the selected site, of all hardware types.';
export const SiteFilledTooltip =
  '<b>Filled</b> refers to the number of ecoTOTE(s), at the selected site, at full capacity.';
export const SitePartiallyTooltip =
  '<b>Partially Consumed</b> refers to the number of ecoTOTE(s), at the selected site, that has been partially depleted.';
export const SiteEmptyTooltip =
  '<b>Empty</b> refers to the number of ecoTOTE(s) that has been fully depleted at the selected site.';
export const SiteTotalEcoToteTooltip =
  '<b>Total ecoTOTEs</b> refers to the total number of ecoTOTE(s) at the selected site.';
export const SiteHardwareTypeTooltip =
  '<b>Hardware Type</b> shows the breakdown count for each hardware type at the selected site.';
export const SiteTotalProductInEcoToteTooltip =
  '<b>Total Products in ecoTOTE</b> refers to the total number of products currently held in ecoTOTE(s) at the selected site.';
export const SiteProductByCountryTooltip =
  'refers to the total volume in circulation of each product(s) currently held in ecoTOTE(s) at the selected site.';
export const SiteTotalConsumedTooltip =
  '<b>Total Consumed Volume (L)</b> refers to the volume of selected managed products consumed via depletion, priming, and dispense from ecoTOTE(s) and/or SmartPour(s), at the selected site, during the selected time period.';
export const SiteProductbyCurrentVolume =
  '<b>Current Volume</b> refers to the total volume in circulation of each product(s) currently held in ecoTOTE(s) at the selected site.';
export const SitePackagingWasteEliminated =
  '<b>Packaging Waste Elminated</b> refers to the amount packaging waste estimated to have been eliminated during the selected period from managed product(s).';
export const SiteCO2eAvoided =
  '<b>CO2e Avoided (kg)</b> refers to the amount carbon dioxide emission estimated to have been avoided during the selected period from managed product(s).';
