import {
  DashboardFilterDataT,
  DashboardSearchParamsI,
  HardwareEcoToteStatusT,
  HardwarePerformanceT,
  ProductPerformanceI,
  SitePerformanceT,
} from '../interfaces/dashboard';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetDashboardProductPerformance = (params: DashboardSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const {
    data: response,
    error,
    isLoading,
    refetch,
  } = useFetch<{ data: ProductPerformanceI }>(`dashboard/product-performance?${newParams}`);
  const data = response?.data;
  return { data, error, isLoading, refetch };
};

export const useGetDashboardHardwarePerformance = (params: DashboardSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const {
    data: response,
    error,
    isLoading,
    refetch,
  } = useFetch<{ data: HardwarePerformanceT }>(`dashboard/hardware-performance?${newParams}`);
  const data = response?.data;
  return { data, error, isLoading, refetch };
};

export const callDashboardEcoToteStatus = async (params?: { product: string }) => {
  const newParams = handleFilterParams(params);
  const { data: response } = await api.get<{ data: HardwareEcoToteStatusT }>(
    `dashboard/hardware-performance/ecotote-status?${newParams}`,
  );
  return response;
};

export const callDashboardSiteEcoToteStatus = async (params?: { product: string }) => {
  const newParams = handleFilterParams(params);
  const { data: response } = await api.get<{ data: HardwareEcoToteStatusT }>(
    `dashboard/site-performance/ecotote-status?${newParams}`,
  );
  return response;
};

export const useGetDashboardSitePerformance = (params: DashboardSearchParamsI & { siteId: number }) => {
  const newParams = handleFilterParams(params);
  const {
    data: response,
    error,
    isLoading,
    refetch,
  } = useFetch<{ data: SitePerformanceT }>(`dashboard/site-performance?${newParams}`);
  const data = response?.data;
  return { data, error, isLoading, refetch };
};

export const useGetDashboardSiteFilter = (params: DashboardSearchParamsI & { siteId: number }) => {
  const newParams = handleFilterParams(params);
  const { data: response, isLoading } = useFetch<{ data: DashboardFilterDataT }>(
    `dashboard/site-performance/filter?${newParams}`,
  );
  const data = response?.data;
  return { data, isLoading };
};

export const useGetDashboardProductFilter = (params: DashboardSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const { data: response, isLoading } = useFetch<{ data: DashboardFilterDataT }>(
    `dashboard/product-performance/filter?${newParams}`,
  );
  const data = response?.data;
  return { data, isLoading };
};

export const useGetDashboardHardwareFilter = (params: DashboardSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const { data: response, isLoading } = useFetch<{ data: DashboardFilterDataT }>(
    `dashboard/hardware-performance/filter?${newParams}`,
  );
  const data = response?.data;
  return { data, isLoading };
};
