import React, { useState } from 'react';
import { HardwareRegisterParamsI, HardwareScannedStateI, HardwareVerifyCollectionI } from '../../interfaces/hardware';
import SecondaryButton from '../buttons/SecondaryButton';
import Divider from '../Divider';
import { Form, Input, Spin, message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { convertToApiFile, GetConfigUnit } from '../../utils/global';
import UploadAttachmentButton from '../buttons/UploadAttachmentButton';
import ConfirmButton from '../buttons/ConfirmButton';
import { handleGetUniqType } from '../../utils/hardware';
import { AttachmentCategories } from '../../constants';
import NonClosableModal from '../NonClosableModal';
import { SiteI } from '../../interfaces/site';
import Icon from '../Icon';
import Text from '../Text';
import { ConfigurationI } from '../../interfaces/configuration';
import DetailsView from '../DetailsView';
import ConfigurationSuccessModal from './SuccessModal';
import { doApplyConfiguration } from '../../apis/configurations';

type Props = {
  verifiedData?: HardwareVerifyCollectionI;
  successData: any;
  site?: SiteI;
  configuration?: ConfigurationI;
  previousStep?: number;
  setCurrentStep: (step: number) => void;
  setScannedData: (_: any) => void;
  setSuccessData: (_: any) => void;
  setFinalScannedData: (_: HardwareScannedStateI[]) => void;
};

const ConfigurationReviewStep: React.FC<Props> = ({
  successData,
  verifiedData,
  site,
  configuration,
  previousStep,
  setCurrentStep,
  setScannedData,
  setSuccessData,
  setFinalScannedData,
}) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState<any[]>([]);
  const [isSuccessModal, setIsSuccessModal] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const ConfirmContent = () => {
    return (
      <div>
        <h2>Proceed to apply configuration to hardware?</h2>
        <Text variant="bodyLg">
          Upon confirmation, configuration{' '}
          <span className="tw-font-bold">
            {configuration?.name} - {configuration?.code}
          </span>{' '}
          will be applied to 30 Hardware.
        </Text>
      </div>
    );
  };

  const handleModalCancel = () => {
    setIsSuccessModal(false);
    form.resetFields();
  };

  const { mutate: doCreateMutate, isLoading: isCreateLoading } = useMutation(doApplyConfiguration, {
    onSuccess: async (data) => {
      setSuccessData(data);
      queryClient.invalidateQueries();
      setIsSuccessModal(true);
      setScannedData([]);
      setFinalScannedData([]);
    },
    onError: () => {
      message.error('Your action was failed! Please try again');
    },
  });

  const handleSubmit = (values: HardwareRegisterParamsI) => {
    const newValues: any = values;
    const hardwareList = verifiedData?.hardwareList?.map((item: any) => {
      delete item.isInDB;
      delete item.error;
      delete item.currentData;
      delete item.type;
      return item;
    });

    newValues.hardwareList = hardwareList;
    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }
    newValues.siteId = site?.id;
    newValues.hardwareConfigCode = configuration?.code;
    doCreateMutate(newValues);
  };

  return (
    <Spin spinning={!verifiedData || isCreateLoading}>
      <div className="component-hardware-depletion-review-step">
        <Form
          form={form}
          requiredMark={false}
          colon={false}
          onFinish={handleSubmit}
          scrollToFirstError
          layout="vertical"
        >
          <DetailsView title="Apply Configuration Details">
            <DetailsView.Field label="Configuration Name" value={configuration?.name} />
            <DetailsView.Field label="Configuration Code" value={configuration?.code} />
            <DetailsView.Field label="Total Number of Hardware" value={verifiedData?.numberOfValid} />
            <DetailsView.Field
              label="Hardware Type"
              className="tw-flex tw-flex-col"
              value={handleGetUniqType(verifiedData?.hardwareList ?? []).map(
                (item: { type: string; count: number }) => (
                  <>
                    {item.type.includes('ecoTOTE') && (
                      <span className="tw-flex tw-gap-2 tw-items-center">
                        <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                          <Icon name="icon-eco-tote" size={20} /> <span>{item.type}</span>
                        </Text>
                      </span>
                    )}
                    {item.type.includes('ecoPLANT') && (
                      <span className="tw-flex tw-gap-2 tw-items-center">
                        <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                          <Icon name="icon-eco-plant" size={20} /> <span>{item.type}</span>
                        </Text>
                      </span>
                    )}
                    {item.type.includes('SmartPour') && (
                      <span className="tw-flex tw-gap-2 tw-items-center">
                        <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                          <Icon name="icon-smart-pour" size={20} /> <span>{item.type}</span>
                        </Text>
                      </span>
                    )}
                  </>
                ),
              )}
            />
            <Divider />
            <DetailsView.Field label="Site" value={site?.displayName} />
            <div className="form-container">
              <div className="form-content !tw-my-0">
                <Form.Item label="Remarks" name="remarks">
                  <Input.TextArea placeholder="Type remarks" rows={5} />
                </Form.Item>
                <p className="label">Media Attachments</p>
                <UploadAttachmentButton
                  categories={AttachmentCategories}
                  form={form}
                  setFiles={setFiles}
                  files={files}
                />
              </div>
            </div>
          </DetailsView>
          <DetailsView title="Configuration Settings" className="tw-mt-4">
            {/* <DetailsView.Field label="Display Unit" value={`${GetConfigUnitText(configuration?.displayUnit ?? '')}`} /> */}
            <div className="tw-bg-grey-100 tw-p-[20px] tw-rounded-lg tw-border tw-border-solid tw-border-grey-500">
              <div className="flex-row tw-mb-5">
                <DetailsView.Field
                  label="Button 1"
                  value={`${configuration?.values?.button1?.toFixed(1)} ${GetConfigUnit(configuration?.displayUnit ?? '')}`}
                />
                <DetailsView.Field
                  label="Button 2"
                  value={`${configuration?.values?.button2?.toFixed(1)} ${GetConfigUnit(configuration?.displayUnit ?? '')}`}
                />
              </div>
              <div className="flex-row tw-mb-5">
                <DetailsView.Field
                  label="Button 3"
                  value={`${configuration?.values?.button3?.toFixed(1)} ${GetConfigUnit(configuration?.displayUnit ?? '')}`}
                />
                <DetailsView.Field label="Button 4" value={`${configuration?.values?.button4?.toFixed(1)} mL`} />
              </div>
              <div className="flex-row tw-mb-5">
                <DetailsView.Field label="Button 5" value={`${configuration?.values?.button5?.toFixed(1)} mL`} />
                <div></div>
              </div>
            </div>
          </DetailsView>
        </Form>
        <div className="hardware-footer-section">
          <div>
            <SecondaryButton label="Previous Step" onClick={() => setCurrentStep(previousStep ?? 2)} />
            <ConfirmButton
              type="info"
              label="Finish Scanning"
              onOk={() => form.submit()}
              content={<ConfirmContent />}
              closable={false}
            />
          </div>
        </div>
      </div>
      <NonClosableModal isVisible={isSuccessModal} handleCancel={handleModalCancel} width={506}>
        <ConfigurationSuccessModal data={successData} configurationCode={configuration?.code ?? ''} />
      </NonClosableModal>
    </Spin>
  );
};

export default ConfigurationReviewStep;
