import { Form, Spin, message } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { SiteI } from '../../../interfaces/site';
import SiteForm from '../../../components/forms/site/Form';
import { CurrentUserI } from '../../../interfaces/auth';
import { CreateUpdateTopSection } from '../../../components/shared/CreateUpdateTopSection';
import { createPlatformSite } from '../../../apis/platforms/sites';

const PlatformSiteNewPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate: createMutate, isLoading: isPageLoading } = useMutation(createPlatformSite, {
    onSuccess: async () => {
      message.info('Create site successfully!');
      queryClient.invalidateQueries({
        queryKey: ['platform-sites', 'list'],
      });
      navigate(`/platform/sites`, { replace: true });
    },
    onError: () => {
      message.error('Create site failed! Please try again');
    },
  });

  const handleSubmit = async (values: SiteI) => {
    createMutate(values);
  };

  return (
    <Spin spinning={isPageLoading}>
      <div className="site-new-container tw-p-4">
        <CreateUpdateTopSection label="Add Site" labelButton="Create" form={form} />
        <SiteForm form={form} handleSubmit={handleSubmit} currentUser={currentUser} />
      </div>
    </Spin>
  );
};

export default PlatformSiteNewPage;
