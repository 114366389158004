import React from 'react';
import { DateTimeFormat, GetConfigUnit } from '../../utils/global';
import DetailsView from '../DetailsView';
import Text from '../Text';
import { ConfigurationI } from '../../interfaces/configuration';
import { handleDisplayHardwareType, handleDisplayIconByType } from '../../utils/hardware';
import { useOutletContext } from 'react-router-dom';
import { CurrentUserI } from '../../interfaces/auth';
import { displayConfigMode } from '../../utils/configuration';

type Props = {
  data: ConfigurationI;
};

const ConfigurationInformation: React.FC<Props> = ({ data }) => {
  const currentUser: CurrentUserI = useOutletContext();
  return (
    <>
      <DetailsView title="SmartPour Configuration Details">
        <div className="flex-row">
          <DetailsView.Field label="Configuration ID" value={data?.code} />
          <DetailsView.Field
            label="Hardware Type"
            value={
              <Text variant="labelMd" className="tw-flex tw-items-center tw-text-grey-900">
                {handleDisplayIconByType(data?.hardwareType)}
                {handleDisplayHardwareType(data?.hardwareType)}
              </Text>
            }
          />
        </div>
        <DetailsView.Field label="Date Created" value={DateTimeFormat(data?.createdAt)} />
        <DetailsView.Field label="Remarks" value={data?.remarks} />
      </DetailsView>
      <DetailsView title="Configuration Settings" className="tw-mt-4">
        {/* <DetailsView.Field label="Display Unit" value={`${GetConfigUnitText(data?.displayUnit)}`} /> */}
        <div className="flex-row">
          <DetailsView.Field
            label="Button 1"
            value={`${data?.values?.button1?.toFixed(2)} ${GetConfigUnit(data?.displayUnit)}`}
          />
          <DetailsView.Field
            label="Button 2"
            value={`${data?.values?.button2?.toFixed(2)} ${GetConfigUnit(data?.displayUnit)}`}
          />
        </div>
        <div className="flex-row">
          <DetailsView.Field
            label="Button 3"
            value={`${data?.values?.button3?.toFixed(2)} ${GetConfigUnit(data?.displayUnit)}`}
          />
          <DetailsView.Field label="Button 4" value={`${data?.values?.button4?.toFixed(2)} mL`} />
        </div>
        <div className="flex-row">
          <DetailsView.Field label="Button 5" value={`${data?.values?.button5?.toFixed(2)} mL`} />
          <div></div>
        </div>
      </DetailsView>
      {currentUser.partner.isEcoSpirit && (
        <DetailsView title="Extended Configuration Settings" className="tw-mt-4">
          <DetailsView.Field label="Product Group Code" value={data?.productGroupCode} />
          <div className="flex-row">
            <DetailsView.Field label="ecoTOTE Mode" value={displayConfigMode(data?.ecoToteMode)} />
            <DetailsView.Field label="VSR Mode" value={displayConfigMode(data?.vsrMode)} />
          </div>
          <div className="flex-row">
            <DetailsView.Field label="Gradient K Plus" value={data?.gradientKPlus} />
            <DetailsView.Field label="Gradient K Minus" value={data?.gradientKMinus} />
          </div>
        </DetailsView>
      )}
    </>
  );
};

export default ConfigurationInformation;
