import { Checkbox, Form } from 'antd';
import React, { useEffect, useMemo } from 'react';
import PrimaryButton from '../../buttons/PrimaryButton';
// import './FilterContent.scss';
import Text from '../../Text';
import SecondaryButton from '../../buttons/SecondaryButton';
import { useQuery } from 'react-query';
import SelectAllCheckbox from '../../SelectAllCheckbox';
import { TransferSearchParamsI } from '../../../interfaces/transfer';
import { getPartnersList } from '../../../apis/partners';
import { PartnerSearchParams } from '../../../interfaces/partner';

type FormParams = {
  search: string;
  newOwnewId: string;
};

const partnerQueryParams: PartnerSearchParams = {
  orderBy: 'displayName',
  orderType: 'desc',
};

type Props = {
  queryParams: TransferSearchParamsI;
  onChange(newParams: Partial<TransferSearchParamsI>): void;
};

const TransferFilterContent: React.FC<Props> = ({ queryParams, onChange }) => {
  const [form] = Form.useForm();

  const handleResetFilter = () => {
    form.resetFields();
    onChange({ filter: {} });
  };

  const handleOnFinish = (values: FormParams) => {
    onChange({
      filter: { newOwnerId: values?.newOwnewId },
      search: values?.search,
    });
  };

  const { data: { partners = [] } = {} } = useQuery(
    ['partners', 'list', partnerQueryParams],
    () => getPartnersList(partnerQueryParams),
    {
      select({ data: { partners, total } }) {
        return { partners, total };
      },
    },
  );

  const partnerOptions = useMemo(() => {
    return partners
      .map((item) => {
        return { label: item.displayName, value: `${item.id}` };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [partners]);

  useEffect(() => {
    form.setFieldsValue({
      newOwnerId: queryParams.filter?.newOwnerId,
    });
  }, [queryParams]);

  return (
    <div className="component-hardware-filter-content-container partner-filter-content">
      <Text variant="h3">Filter</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        layout="vertical"
        className="tw-max-h-[580px]"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className="form-content tw-max-h-[450px] tw-overflow-auto tw-pr-4">
            <div className="status-section">
              <Form.Item name="siteIds">
                <SelectAllCheckbox form={form} plainOptions={partnerOptions} name="newOwnerId" sectionTitle="Owner">
                  {partnerOptions.map((site: { label: string; value: string }) => (
                    <Checkbox key={site.value} value={site.value}>
                      {site.label}
                    </Checkbox>
                  ))}
                </SelectAllCheckbox>
              </Form.Item>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3 tw-pb-4">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TransferFilterContent;
