import React from 'react';
import Text from '../Text';
import TooltipIcon from '../buttons/TooltipIcon';

type Props = {
  period?: string;
  tooltipText: string;
  percentage: number | string;
  title: string;
  color: string;
  totalNumber: number | string;
};

const DetailsCard: React.FC<Props> = ({ period, tooltipText, percentage, title, color, totalNumber }) => {
  return (
    <div
      className="tw-rounded-lg
      tw-border
      tw-border-solid
      tw-border-grey-300
      tw-p-2 tw-mb-3 tw-max-w-full tw-min-w-[120px]"
    >
      <div className="tw-flex tw-justify-between tw-items-start">
        <div className="tw-flex tw-items-start tw-gap-1">
          <span className="tw-max-w-4 tw-w-4 tw-h-4 tw-rounded-[50%] tw-inline-block" style={{ background: color }} />
          <span>
            <Text variant="inputLabelSm" className="tw-flex" component="p">
              {title}
            </Text>
            {period && (
              <Text variant="inputLabelSm" className="tw-block tw-text-grey-700" component="p">
                {period}
              </Text>
            )}
          </span>
        </div>
        <TooltipIcon text={tooltipText} />
      </div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <Text variant="h4" className="tw-text-grey-800 tw-pl-5">
          {totalNumber}
        </Text>
        <Text variant="inputLabelMd" className="tw-text-grey-700">
          {percentage}%
        </Text>
      </div>
    </div>
  );
};

export default DetailsCard;
