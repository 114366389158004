import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { updateConfiguration, useGetConfiguration } from '../../apis/configurations';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';
import { Form, message, Modal, Spin } from 'antd';
import ConfigurationForm from '../../components/forms/configuration/Form';
import { useMutation, useQueryClient } from 'react-query';
import Text from '../../components/Text';
import { useAppNotification } from '../../hooks/useAppNotification';

const RenderConfirmText = ({ hardwareNo }: { hardwareNo: number }) => {
  return (
    <div>
      <h2>Proceed to update this Configuration Preset?</h2>
      <Text variant="bodyLg">
        This preset is currently applied to <span className="tw-font-bold">{hardwareNo} hardware</span>.
      </Text>
      <ul className="tw-pl-5">
        <li>
          Upon confirmation, the updated preset will be saved but not applied to the{' '}
          <span className="tw-font-bold">{hardwareNo} hardware.</span>
        </li>
        <li>
          The <span className="tw-font-bold">{hardwareNo} hardware</span> will retain their existing hardware
          configuration preset.
        </li>
      </ul>
    </div>
  );
};

const ConfigurationUpdatePage: React.FC = () => {
  const { code } = useParams<{ code?: string }>();
  const { data: config, isLoading, error } = useGetConfiguration(code);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { confirm } = Modal;
  const { openNotification } = useAppNotification();

  if (error === 'Config not found!') {
    navigate('/not-allowed', { replace: true });
  }

  const handleModalCancel = () => {
    form.resetFields();
  };

  const { mutate: updateMutate, isLoading: isPageLoading } = useMutation(updateConfiguration, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries();
      openNotification({
        type: 'success',
        title: 'Successfully updated configuration preset!',
        content: (
          <span>
            Configuration preset has been detached from{' '}
            <span className="tw-font-bold">{data?.hardwares.length ?? 0} hardware</span>
          </span>
        ),
      });
      navigate(`/configurations/${data.code}`);
      handleModalCancel();
    },
    onError: () => {
      message.error('Your action failed! Please try again');
      handleModalCancel();
    },
  });

  const confirmFunction = () => {
    confirm({
      content: <RenderConfirmText hardwareNo={config?.hardwares?.length ?? 0} />,
      onOk() {
        form.submit();
      },
      okText: 'Confirm',
      cancelText: 'Back',
      wrapClassName: `confirm-content-wrapper`,
      closable: true,
      keyboard: true,
      width: 596,
    });
  };

  const handleSubmit = (values: any) => {
    updateMutate({ code: code ?? '', values });
  };

  return (
    <div className="tw-p-4">
      <CreateUpdateTopSection
        label="Edit SmartPour Configuration"
        labelButton="Update"
        form={form}
        confirmFunction={confirmFunction}
      />
      <Spin spinning={isLoading ?? isPageLoading}>
        <ConfigurationForm form={form} type={config?.hardwareType} handleSubmit={handleSubmit} configuration={config} />
      </Spin>
    </div>
  );
};

export default ConfigurationUpdatePage;
