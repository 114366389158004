import { Spin } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetConfiguration } from '../../apis/configurations';
import ConfigurationTopSection from '../../components/Configuration/TopSection';
import ConfigurationInformation from '../../components/Configuration/Information';
import ConfigurationResources from '../../components/Configuration/Resources';

const ConfigurationDetailsPage: React.FC = () => {
  const { code } = useParams<{ code?: string }>();
  const { data: config, isLoading, error } = useGetConfiguration(code);
  const navigate = useNavigate();

  if (error === 'Config not found!') {
    navigate('/not-allowed', { replace: true });
  }

  return (
    <Spin spinning={isLoading}>
      <div className="tw-p-4">
        <ConfigurationTopSection data={config} />
        <ConfigurationInformation data={config} />
        <ConfigurationResources data={config} />
      </div>
    </Spin>
  );
};

export default ConfigurationDetailsPage;
