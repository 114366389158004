import React from 'react';
import './Information.scss';
import { AllocationHistoryI } from '../../interfaces/allocationHistory';
import DetailsView from '../DetailsView';
import { handleGetUniqType } from '../../utils/hardware';
import Text from '../Text';
import Icon from '../Icon';

type Props = {
  allocationHistory: AllocationHistoryI;
};

const AllocationHistoryInformation: React.FC<Props> = ({ allocationHistory }) => {
  console.log('allocationHistory', allocationHistory);

  return (
    <div className="allocation-history-information-container details-container">
      <DetailsView title="Allocation Details">
        <DetailsView.Field label="Number of Hardware" value={allocationHistory?.successHardwareCount} />
        <DetailsView.Field
          label="Hardware Type"
          value={handleGetUniqType(allocationHistory?.hardwareList ?? []).map(
            (item: { type: string; count: number }) => (
              <>
                {item.type.includes('ecoTOTE') && (
                  <span className="tw-flex tw-gap-2 tw-items-center">
                    <Text variant="bodyLgB" className="tw-min-w-[24px]">
                      {item.count}
                    </Text>
                    <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                      <Icon data-testid="icon-eco-tote" name="icon-eco-tote" size={20} /> <span>{item.type}</span>
                    </Text>
                  </span>
                )}
                {item.type.includes('ecoPLANT') && (
                  <span className="tw-flex tw-gap-2 tw-items-center">
                    <Text variant="bodyLgB" className="tw-min-w-[24px]">
                      {item.count}
                    </Text>
                    <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                      <Icon name="icon-eco-plant" size={20} /> <span>{item.type}</span>
                    </Text>
                  </span>
                )}
                {item.type.includes('SmartPour') && (
                  <span className="tw-flex tw-gap-2 tw-items-center">
                    <Text variant="bodyLgB" className="tw-min-w-[24px]">
                      {item.count}
                    </Text>
                    <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                      <Icon name="icon-smart-pour" size={20} /> <span>{item.type}</span>
                    </Text>
                  </span>
                )}
              </>
            ),
          )}
        />
      </DetailsView>
    </div>
  );
};

export default AllocationHistoryInformation;
