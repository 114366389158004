import { Button, Tooltip } from 'antd';
import React from 'react';
import Icon from '../Icon';

type Props = {
  text: string;
  className?: string;
  iconSize?: number;
  buttonClassName?: string;
};

const TooltipIcon: React.FC<Props> = ({ text, className, iconSize = 16, buttonClassName }) => {
  return (
    <Tooltip
      placement="bottom"
      title={<span dangerouslySetInnerHTML={{ __html: text }} />}
      arrow={true}
      className={`tw-max-w-[210px] ${className}`}
    >
      <Button
        className={`tw-p-0 tw-border-none tw-shadow-none tw-w-4 !tw-h-4 tw-text-info-500 hover:!tw-text-info-500 ${buttonClassName}`}
      >
        <Icon name="icon-info" size={iconSize} />
      </Button>
    </Tooltip>
  );
};

export default TooltipIcon;
