import React, { Dispatch } from 'react';
import { DashboardFilterDataT, DashboardSearchParamsI, HardwarePerformanceT } from '../../interfaces/dashboard';
import { Spin } from 'antd';
import DashboardProductFilter from '../forms/dashboard/ProductFilter';
import {
  dateRangeOptions,
  HardwareEcoToteInteractionsByCountryTooltip,
  HardwareTotalConsumedTooltip,
} from '../../constants/dashboard';
import { DateRangeT } from '../../interfaces';
import DashboardProductionVolume from './ProductionVolume';
import EcoToteUsage from './EcoToteUsage';
import DashboardTotalConsumedValue from './TotalConsumedValue';

type Props = {
  data?: HardwarePerformanceT;
  isLoading: boolean;
  activeDateRange: DateRangeT;
  queryParams: DashboardSearchParamsI;
  totalProductionTooltip: string;
  ecoToteInteractionTooltip: string;
  filterData?: DashboardFilterDataT;
  setQueryParams: (params: DashboardSearchParamsI) => void;
  setActiveDateRange: Dispatch<React.SetStateAction<DateRangeT>>;
};

const HardwareProduct: React.FC<Props> = ({
  data,
  isLoading,
  activeDateRange,
  queryParams,
  totalProductionTooltip,
  ecoToteInteractionTooltip,
  filterData,
  setQueryParams,
  setActiveDateRange,
}) => {
  return (
    <div className="tw-px-4 sm:tw-px-0 sm:tw-rounded-none">
      <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-6 tw-mt-6 sm:tw-p-4 sm:tw-mt-4">
        <Spin spinning={isLoading}>
          <DashboardProductFilter
            setQueryParams={setQueryParams}
            setActiveDateRange={setActiveDateRange}
            activeDateRange={activeDateRange}
            dateRangeOptions={dateRangeOptions}
            filterData={filterData}
            queryParams={queryParams}
          />

          <div className="tw-mt-3 tw-gap-6 tw-grid tw-grid-cols-2 tw-mb-6 sm:tw-grid-cols-1">
            <DashboardProductionVolume
              data={data?.productionVolume}
              isLoading={isLoading}
              activeDateRange={activeDateRange}
              totalProductionTooltip={totalProductionTooltip}
            />
            <DashboardTotalConsumedValue
              data={data?.consumedVolume}
              isLoading={isLoading}
              activeDateRange={activeDateRange}
              totalConsumedTooltip={HardwareTotalConsumedTooltip}
            />
          </div>
          <EcoToteUsage
            data={data?.ecoToteUsage}
            isLoading={isLoading}
            ecoToteInteractionTooltip={ecoToteInteractionTooltip}
            hardwareEcoToteInteractionsByCountryTooltip={HardwareEcoToteInteractionsByCountryTooltip}
          />
        </Spin>
      </div>
    </div>
  );
};

export default HardwareProduct;
