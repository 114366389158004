import { Checkbox, Form, Select } from 'antd';
import React, { Dispatch, useEffect, useState } from 'react';
import PolarisDateRangePicker from '../../DateRangePicker';
import { Countries } from 'hoangdevtrai-country-list-js';
import { DashboardSearchParamsI, DashboardFilterDataT, DashboardTypeT } from '../../../interfaces/dashboard';
import SecondaryButton from '../../buttons/SecondaryButton';
import { DateRangeT } from '../../../interfaces';
import { ProductI } from '../../../interfaces/product';
import Text from '../../Text';
import './ProductFilter.scss';
import { convertDayJSToApi } from '../../../utils/global';
import dayjs from 'dayjs';
import * as Flags from 'country-flag-icons/react/3x2';
import useWindowResize from '../../../hooks/useWindowResize';

interface CountryItemI {
  label: string;
  value: string;
  emoji: string;
}

type Props = {
  queryParams: DashboardSearchParamsI & { siteId?: number; siteType?: string };
  filterData?: DashboardFilterDataT;
  dateRangeOptions: DateRangeT[];
  activeDateRange: DateRangeT;
  type?: DashboardTypeT;
  setActiveDateRange: Dispatch<React.SetStateAction<DateRangeT>>;
  setQueryParams: (params: DashboardSearchParamsI & { siteId?: number; siteType?: string }) => void;
};

const DashboardProductFilter: React.FC<Props> = ({
  filterData,
  activeDateRange,
  dateRangeOptions,
  queryParams,
  type,
  setActiveDateRange,
  setQueryParams,
}) => {
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [products, setProducts] = useState<Partial<ProductI>[]>([]);
  const [countries, setCountries] = useState<CountryItemI[]>([]);
  const [form] = Form.useForm();
  const { width } = useWindowResize();

  useEffect(() => {
    if (filterData) {
      const matchedCountries = Countries.filter((country) => filterData?.countries?.includes(country.value));
      setCountries(matchedCountries);
      setProducts(filterData.products);
    }
  }, [filterData]);

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      startDate: convertDayJSToApi(dayjs(activeDateRange?.period.since)),
      endDate: convertDayJSToApi(dayjs(activeDateRange?.period.until)),
    });
  }, [activeDateRange]);

  useEffect(() => {
    setSelectedCountries(queryParams.country ?? []);
    setSelectedProducts(queryParams.product ?? []);
    form.setFieldsValue({
      country: queryParams.country,
      product: queryParams.product,
    });
  }, [queryParams, filterData]);

  const handleCountryChange = (value: string[]) => {
    setSelectedCountries(value);
    setQueryParams({
      ...queryParams,
      country: value,
    });
  };

  const handleProductChange = (value: string[]) => {
    setSelectedProducts(value);
    setQueryParams({
      ...queryParams,
      product: value,
    });
  };

  const handleReset = () => {
    form.resetFields();
    setSelectedCountries([]);
    setSelectedProducts([]);
    setActiveDateRange(dateRangeOptions[0]);
  };

  return (
    <div className="filter-form-container product-performance-filter-container">
      <div className="form-wrap">
        <Form form={form} requiredMark={false} colon={false} scrollToFirstError className="tw-w-full" layout="vertical">
          <div className="form-container">
            <div className="tw-flex tw-flex-auto tw-flex-row tw-gap-4 !tw-items-end sm:tw-flex-col sm:!tw-items-start">
              <Form.Item
                label={width < 767 ? '' : 'Date'}
                name="date"
                className="tw-flex-1 [&>div>div>div>div>div]:tw-w-full sm:tw-w-full"
              >
                <PolarisDateRangePicker
                  setActiveDateRange={setActiveDateRange}
                  activeDateRange={activeDateRange}
                  ranges={dateRangeOptions}
                />
              </Form.Item>
              <Form.Item label={width < 767 ? '' : 'Product'} name="product" className="tw-flex-1 sm:tw-w-full">
                <Select
                  mode="multiple"
                  value={selectedProducts}
                  onChange={handleProductChange}
                  placeholder="All Products"
                  showSearch
                  optionFilterProp="children"
                  className="tw-p-0 tw-w-full !tw-mb-0"
                  rootClassName="site-selector-root"
                  dropdownRender={(menu) => (
                    <div className="tw-p-4">
                      <Text variant="h4" className="tw-mb-1">
                        Product Filter
                      </Text>
                      {menu}
                    </div>
                  )}
                  allowClear
                  optionLabelProp="label"
                >
                  {products.map((product: any) => (
                    <Select.Option key={product.sku} value={product.sku} label={product.name}>
                      <span className="tw-flex tw-gap-1">
                        <Checkbox checked={selectedProducts.includes(product.sku)} />
                        {product.name}
                      </span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {type !== 'site' && (
                <Form.Item label={width < 767 ? '' : 'Country'} name="country" className="tw-flex-1 sm:tw-w-full">
                  <Select
                    mode="multiple"
                    value={selectedCountries}
                    onChange={handleCountryChange}
                    placeholder={<span>All Countries</span>}
                    showSearch
                    optionFilterProp="children"
                    rootClassName="product-performance-filter"
                    dropdownRender={(menu) => (
                      <div className="tw-p-4">
                        <Text variant="h4">Country Filter</Text>
                        {menu}
                      </div>
                    )}
                    optionLabelProp="label"
                  >
                    {countries.map((option) => {
                      const CountryFlag = Flags[option.value as keyof typeof Flags];
                      return (
                        <Select.Option key={option.value} value={option.value} label={option.label}>
                          <span className="tw-flex tw-gap-1">
                            <Checkbox checked={selectedCountries.includes(option.value)} />
                            {CountryFlag ? (
                              <CountryFlag title={option.label} className="country-flag tw-w-5" />
                            ) : null}{' '}
                            {option.label}
                          </span>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
              <SecondaryButton
                label={width < 767 ? 'Reset' : 'Reset to Default'}
                onClick={handleReset}
                className="sm:tw-w-full"
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DashboardProductFilter;
