import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Query, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import './global.scss';
import './output.scss';
import '@shopify/polaris/build/esm/styles.css';
import { ConfigProvider, message } from 'antd';
import { NotificationProvider } from './hooks/useAppNotification';
import { AppProvider } from '@shopify/polaris';
import en from '@shopify/polaris/locales/en.json';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: 1,
    },
  },
  queryCache: new QueryCache({
    onError: (error: any, query: Query) => {
      if (query.state.data !== undefined) {
        message.error(`Something went wrong: ${error?.message}`);
      }
    },
  }),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Poppins',
            colorPrimary: '#64aa4b',
            controlHeight: 40,
          },
        }}
      >
        <NotificationProvider>
          <AppProvider i18n={en}>
            <App />
          </AppProvider>
        </NotificationProvider>
      </ConfigProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
