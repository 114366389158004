import React from 'react';
import Text from '../../components/Text';
import { CurrentUserI } from '../../interfaces/auth';
import { useOutletContext } from 'react-router-dom';
import { handleAccessControl } from '../../utils/role';
import TabLink from '../../components/TabLink';
import useWindowResize from '../../hooks/useWindowResize';
import TooltipIcon from '../../components/buttons/TooltipIcon';

const DashboardPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const productAccess = handleAccessControl(currentUser, 'product-performance');
  const hardwareAccess = handleAccessControl(currentUser, 'hardware-performance');
  const siteAccess = handleAccessControl(currentUser, 'site-performance');
  const { width } = useWindowResize();
  const SiteTooltip =
    'Site Performance offers insights into the performance of individual sites managed by your partnership.';
  const ProductTooltip =
    'Product Performance offers insights into the performance of products managed by your partnership.';
  const HardwareTooltip =
    'Hardware Performance provides insights into the performance of hardware managed by your partnership.';

  return (
    <>
      <div className="tw-p-4 tw-pb-0">
        <Text component={width < 767 ? 'h3' : 'h2'} variant={width < 767 ? 'h3' : 'h2'} className="tw-mb-4">
          Performance Dashboard
        </Text>
      </div>
      <TabLink currentUser={currentUser}>
        {siteAccess.isCanRead && (
          <TabLink.TabItem
            linkTo="/performance/site"
            label={
              width < 767 ? (
                <span className="tw-flex tw-items-center tw-gap-2">
                  Site <TooltipIcon text={SiteTooltip} iconSize={20} />
                </span>
              ) : (
                'Site Performance'
              )
            }
            icon="icon-pin_drop"
          />
        )}
        {productAccess.isCanRead && (
          <TabLink.TabItem
            linkTo="/performance/product"
            label={
              width < 767 ? (
                <span className="tw-flex tw-items-center tw-gap-2">
                  Product <TooltipIcon text={ProductTooltip} iconSize={20} />
                </span>
              ) : (
                'Product Performance'
              )
            }
            icon="icon-wine_bar"
          />
        )}
        {hardwareAccess.isCanRead && (
          <TabLink.TabItem
            linkTo="/performance/hardware"
            label={
              width < 767 ? (
                <span className="tw-flex tw-items-center tw-gap-2">
                  Hardware <TooltipIcon text={HardwareTooltip} iconSize={20} />
                </span>
              ) : (
                'Hardware Performance'
              )
            }
            icon="icon-all_inbox"
          />
        )}
      </TabLink>
    </>
  );
};

export default DashboardPage;
