import React from 'react';
import EmptyDataImage from '../images/EmptyData.png';
import './EmptyData.scss';
import Text from './Text';
import { Link } from 'react-router-dom';

type Props = {
  message?: string;
  title?: string;
  button?: { title: string; url: string };
  imageSize?: { width: string; height: string };
};

const EmptyData: React.FC<Props> = ({ message, title, button, imageSize }) => {
  return (
    <div className="component-empty-data-container">
      <div className="tw-flex tw-flex-col tw-items-center tw-gap-3">
        <img src={EmptyDataImage} alt="Data is empty" style={imageSize} />
        {title && <Text variant="h3">{title}</Text>}
        {message && (
          <Text variant="bodyMd" className="tw-text-grey-700 tw-max-w-[288px] tw-text-center">
            {message}
          </Text>
        )}
        {button && (
          <Link
            to={button.url}
            className="tw-p-3 tw-bg-white tw-rounded tw-min-w-[236px] tw-border tw-border-solid tw-border-success-primary tw-text-center"
          >
            {button.title}
          </Link>
        )}
      </div>
    </div>
  );
};

export default EmptyData;
