import React from 'react';
import './HistoryAndAttachment.scss';
import { Tabs, TabsProps } from 'antd';
import TrackingHistory from './History';
import Icon from '../Icon';
import Text from '../Text';
import TrackingAttachments from './Attachments';
import TrackingDetailGroups from './Groups';
import { HardwareI } from '../../interfaces/hardware';
import TrackingFaultReports from './FaultReports';
import TrackingConfiguration from './Configuration';

type Props = {
  data: HardwareI;
  id?: string;
  currentTab: string;
  refetchData: () => void;
  setCurrentTab?: (_: string) => void;
};

const TrackingResources: React.FC<Props> = ({ data, id, currentTab, refetchData, setCurrentTab }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <Text variant="labelLg" className="tab-title">
          <Icon name="icon-history" size={20} /> <span>History</span>
        </Text>
      ),
      children: <TrackingHistory id={id} hardwareType={data?.type} />,
    },
    {
      key: '2',
      label: (
        <Text variant="labelLg" className="tab-title">
          <Icon name="icon-group_work" size={20} /> <span>Groups</span>
        </Text>
      ),
      children: <TrackingDetailGroups data={data} />,
    },
    {
      key: '3',
      label: (
        <Text variant="labelLg" className="tab-title">
          <Icon name="icon-error_outline" /> <span>Fault Report</span>
        </Text>
      ),
      children: <TrackingFaultReports id={id} />,
    },
    ...(data?.type === 'smartpour'
      ? [
          {
            key: '5',
            label: (
              <Text variant="labelLg" className="tab-title">
                <Icon name="icon-build_circle" /> <span>Configuration</span>
              </Text>
            ),
            children: <TrackingConfiguration hardware={data} refetchData={refetchData} />,
          },
        ]
      : []),
    {
      key: '4',
      label: (
        <Text variant="labelLg" className="tab-title">
          <Icon name="icon-attach_file" /> <span>Attachments</span>
        </Text>
      ),
      children: <TrackingAttachments attachments={data?.attachments} />,
    },
  ];

  const handleTabChange = (key: string) => {
    setCurrentTab?.(key);
  };

  return (
    <div className="tracking-hardware-attachment-container" id="tracking-resources" style={{ marginBottom: 16 }}>
      <div className="content-wrap">
        <Tabs
          defaultActiveKey={currentTab}
          activeKey={currentTab}
          onChange={handleTabChange}
          items={items}
          rootClassName="app-tabs"
        />
      </div>
    </div>
  );
};

export default TrackingResources;
