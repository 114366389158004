import React, { useMemo } from 'react';
import ECharts from '../charts/ECharts';
import { EChartsOption } from 'echarts';
import Text from '../Text';
import Icon from '../Icon';
import { DashboardProductionVolumeT, DashboardReceivedVolumeT } from '../../interfaces/dashboard';
import TooltipIcon from '../buttons/TooltipIcon';
import { convertLiterToMl, convertStringtoDate } from '../../utils/global';
import { Spin } from 'antd';
import { DateRangeT } from '../../interfaces';
import { mapDataForDateRange } from '../../utils/dashboard';
import dayjs from 'dayjs';
import { SiteTypeT } from '../../interfaces/site';
import useWindowResize from '../../hooks/useWindowResize';

type Props = {
  data?: Partial<DashboardProductionVolumeT & DashboardReceivedVolumeT>;
  isLoading: boolean;
  activeDateRange: DateRangeT;
  siteType?: SiteTypeT;
  totalProductionTooltip: string;
};

const DashboardProductionVolume: React.FC<Props> = ({
  data,
  isLoading,
  activeDateRange,
  siteType = 'filling',
  totalProductionTooltip,
}) => {
  const { width } = useWindowResize();
  const barChartOption: EChartsOption = useMemo(() => {
    const mappedData = mapDataForDateRange(data?.data ?? {}, activeDateRange);

    let getXAxisData: string[];
    let seriesData: (string | null)[];

    if (activeDateRange.alias === 'thisyear') {
      const monthlyData: { [key: string]: number } = {};
      Object.entries(mappedData).forEach(([date, value]) => {
        const newDate = dayjs(date, 'DD-MM-YYYY');
        const month = dayjs(newDate).format('MMM');

        if (month !== null && !monthlyData[month]) {
          monthlyData[month] = 0;
        }
        if (month !== null && value !== null) {
          monthlyData[month] += value;
        }
      });
      getXAxisData = Object.keys(monthlyData);
      seriesData = Object.values(monthlyData).map((value) => (value === 0 ? null : convertLiterToMl(value)));
    } else {
      getXAxisData = Object.keys(mappedData)
        .map((item) => convertStringtoDate(item))
        .filter((item): item is string => item !== null);
      seriesData = Object.values(mappedData).map((value) => (value === 0 ? null : convertLiterToMl(value)));
    }

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        data: getXAxisData,
        axisLine: {
          lineStyle: {
            color: '#ccc',
          },
        },
        axisLabel: {
          color: '#666',
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#ccc',
          },
        },
        axisLabel: {
          color: '#666',
        },
        splitLine: {
          lineStyle: {
            type: 'solid',
          },
        },
      },
      series: [
        {
          name: siteType !== 'filling' ? 'Allocation (Receive)' : 'Fill',
          type: 'bar',
          data: seriesData,
          itemStyle: {
            color: siteType !== 'filling' ? '#EDD5BF' : '#C27329',
          },
          label: {
            show: false,
          },
          barMaxWidth: 20,
          barGap: 0,
          barMinWidth: 11,
        },
      ],
    };
  }, [data, activeDateRange, siteType]);

  return (
    <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-6 sm:tw-p-4">
      <Spin spinning={isLoading}>
        <Text
          component={width < 767 ? 'h3' : 'h2'}
          variant={width < 767 ? 'h3' : 'h2'}
          className="tw-mb-4 tw-flex tw-items-center tw-text-grey-800"
        >
          Total {siteType !== 'filling' ? 'Received' : 'Production'} Volume
          <Icon name="icon-chevron_right" />
        </Text>
        <div className="tw-flex tw-flex-row tw-gap-3 tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-3">
          <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-3 tw-flex tw-items-center">
            <Icon name="icon-local_drink_filled" size={40} className="tw-text-warning-300" />
          </div>
          <div className="tw-flex tw-flex-col tw-gap-y-0.5">
            <div className="tw-flex tw-items-center tw-gap-1">
              <Text variant="h4" className="tw-text-grey-700">
                Total {siteType !== 'filling' ? 'Received' : 'Production'} (L)
              </Text>{' '}
              <TooltipIcon text={totalProductionTooltip} />
            </div>
            <Text variant="h1">{`${siteType === 'filling' ? convertLiterToMl(data?.totalProduction) : convertLiterToMl(data?.totalReceived)}`}</Text>
          </div>
        </div>
        <div className="tw-flex tw-flex-wrap">
          <ECharts option={barChartOption} style={{ width: '100%', height: '400px' }} />
          <div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-gap-x-1">
            <span
              className={`tw-w-4 tw-h-4 ${siteType === 'filling' ? 'tw-bg-warning-300' : 'tw-bg-warning-200'} tw-rounded-[50%] tw-inline-block`}
            />{' '}
            <Text variant="inputLabelSm">{siteType === 'filling' ? 'Fill' : 'Allocation (Receive)'}</Text>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default DashboardProductionVolume;
