import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Navigate, useLocation } from 'react-router-dom';
import { TOKEN_NAME } from './constants';

import AdminLayout from './pages/AdminLayout';
import { useGetProfile } from './apis/auth';
import { Spin } from 'antd';
import { CurrentUserI } from './interfaces/auth';
import { handleCheckAuthorize, handleGetNextPath } from './utils/role';
import EAnalytics from './analytics';

const RequireAuth: React.FC = () => {
  const location = useLocation();
  const token = Cookies.get(TOKEN_NAME);
  const profile = useGetProfile(!!token);

  const currentUserId = profile.data?.data?.id;

  useEffect(() => {
    if (currentUserId) {
      EAnalytics.setUserId(currentUserId);
    }
  }, [currentUserId]);

  const renderDashboard = (currentUser: CurrentUserI) => {
    const pathName = handleGetNextPath(currentUser);
    return <Navigate to={pathName} state={{ from: location }} replace />;
  };

  const checkDashboardAuthorize = (userInfo: CurrentUserI) => {
    if (handleCheckAuthorize(userInfo, 'performance/site')) {
      return <Navigate to={`/performance/site`} state={{ from: location }} replace />;
    } else if (handleCheckAuthorize(userInfo, 'performance/product')) {
      return <Navigate to={`/performance/product`} state={{ from: location }} replace />;
    } else if (handleCheckAuthorize(userInfo, 'performance/hardware')) {
      return <Navigate to={`/performance/hardware`} state={{ from: location }} replace />;
    } else {
      return renderDashboard(userInfo);
    }
  };

  const handleAuthorizedUser = (userInfo: CurrentUserI) => {
    if (location.pathname === '/performance/site') {
      const isAuthorize = handleCheckAuthorize(userInfo, location.pathname);
      if (isAuthorize) {
        return <AdminLayout currentUser={userInfo} />;
      } else {
        return checkDashboardAuthorize(userInfo);
      }
    }
    if (location.pathname === '/performance/product') {
      const isAuthorize = handleCheckAuthorize(userInfo, location.pathname);
      if (isAuthorize) {
        return <AdminLayout currentUser={userInfo} />;
      } else {
        return checkDashboardAuthorize(userInfo);
      }
    }
    if (location.pathname === '/performance/hardware') {
      const isAuthorize = handleCheckAuthorize(userInfo, location.pathname);
      if (isAuthorize) {
        return <AdminLayout currentUser={userInfo} />;
      } else {
        return checkDashboardAuthorize(userInfo);
      }
    }

    if (location.pathname === '/') {
      return renderDashboard(userInfo);
    }

    const isAuthorize = handleCheckAuthorize(userInfo, location.pathname);
    if (isAuthorize) {
      return <AdminLayout currentUser={userInfo} />;
    } else {
      return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    }
  };

  const handleProfileStatus = () => {
    if (profile.isSuccess) {
      const userInfo: CurrentUserI = profile.data.data;
      return handleAuthorizedUser(userInfo);
    } else if (profile.isError) {
      Cookies.remove(TOKEN_NAME);
      return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
      return (
        <div className="loading-screen">
          <Spin spinning={true} />
        </div>
      );
    }
  };

  if (token) {
    return handleProfileStatus();
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};

export default RequireAuth;
