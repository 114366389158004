import React, { useEffect, useState } from 'react';
import RoleUsersTable from '../tables/RoleUsers';
import RoleDetailFilterForm from '../forms/role/DetailFilterForm';
import { UserI } from '../../interfaces/user';
import SizeBox from '../SizeBox';
import Text from '../Text';

type Props = {
  users?: UserI[];
};

const RoleUsers: React.FC<Props> = ({ users }) => {
  const [userList, setUserList] = useState<UserI[]>([]);
  const handleFilterSubmit = (values: { search: string }) => {
    const newUsers = users?.filter((user: UserI) => user.username === values.search) ?? [];
    setUserList(newUsers);
  };

  useEffect(() => {
    if (users) {
      setUserList(users);
    }
  }, [users]);

  return (
    <div className="role-users-container tw-mt-6">
      <div className="content-wrap">
        <Text component="h4" variant="h4" className="tw-text-primary-500 tw-mb-6">
          Users List
        </Text>
        <RoleDetailFilterForm handleFilter={handleFilterSubmit} />
        <SizeBox height={16} />
        <RoleUsersTable rowKey="id" pagination={false} dataSource={userList} />
      </div>
    </div>
  );
};

export default RoleUsers;
