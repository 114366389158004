import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import useWindowResize from '../../hooks/useWindowResize';

type Props = {
  option: echarts.EChartsOption;
  style?: React.CSSProperties;
  className?: string;
};

const ECharts: React.FC<Props> = ({ option, style, className }) => {
  const chartRef = React.useRef<HTMLDivElement>(null);
  const { width, height } = useWindowResize();

  useEffect(() => {
    let chart: echarts.ECharts | null = null;
    if (chartRef.current) {
      chart = echarts.init(chartRef.current);
      chart?.setOption(option);

      const handleResize = () => {
        if (chart) {
          chart.resize();
        }
      };
      window.addEventListener('resize', handleResize);
      return () => {
        if (chart) chart.dispose();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [option, width, height]);

  return <div ref={chartRef} style={style} className={className} role="presentation" />;
};

export default ECharts;
