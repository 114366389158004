import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { CommonStatus } from '../CommonStatus';
import { EmailList } from '../../interfaces/emailList';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const EmailListsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Name',
      width: 150,
      ellipsis: true,
      render: (emailList: EmailList) => {
        return <span>{emailList?.name}</span>;
      },
    },
    {
      title: 'No of Recipient',
      width: 150,
      ellipsis: true,
      render: (emailList: EmailList) => {
        return <span>{emailList?._count?.emailListUsers}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      render: (status: string) => {
        return <CommonStatus status={status} />;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default EmailListsTable;
