import { api } from './api';
import { QueryFunctionContext } from 'react-query/types/core/types';
import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosResponse } from 'axios';

type QueryKeyT = [string, Record<string, unknown> | undefined];

export const fetcher = <T>({ queryKey, pageParam }: QueryFunctionContext<QueryKeyT, any>): Promise<T> => {
  const [url, params] = queryKey;
  return api.get<T>(url, { params: { ...params, pageParam } }).then((res: AxiosResponse) => res.data);
};

export const useFetch = <T>(
  url: string | null,
  params?: Record<string, unknown>,
  config?: UseQueryOptions<T, any, T, QueryKeyT>,
) => {
  const context = useQuery<T, any, T, QueryKeyT>([url!, params], ({ queryKey, meta }) => fetcher({ queryKey, meta }), {
    enabled: !!url,
    ...config,
  });
  return context;
};
